import { CaretDownOutlined } from '@ant-design/icons/lib/icons';
import { Badge, Dropdown, Input, List, Typography } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hook';
import { WorksheetStatus } from 'utils/interfaces/worksheets';
import {
    getWorksheetStatusBadge,
    getWorksheetStatusLabel,
} from 'utils/worksheets';
import { FilterOption } from './FilterSelect';
import style from './JournalistSelect.module.scss';

export interface CustomOption extends FilterOption {
    status: WorksheetStatus | undefined;
}

export interface JournalistSelectProps extends SelectProps {
    options?: CustomOption[];
    selectedOptions: CustomOption[];
    selectPlaceholder: string;
    onApplyFilters: (value: string | number) => void;
}

const JournalistSelect = (props: JournalistSelectProps) => {
    const user = useAppSelector((state) => state.user.currentUser);

    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<CustomOption[]>([]);
    const [searchValue, setSearchValue] = useState('');

    const handleOpenChange = (flag: boolean) => {
        setOpen(flag);
    };

    const filteredItems = useMemo(
        () =>
            items?.filter((el) =>
                el.label // label is automatically translated
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()),
            ),
        [searchValue, items],
    );

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onApplyFilters = (el: FilterOption) => {
        props.onApplyFilters(el.value);
        handleOpenChange(false);
    };

    useEffect(() => {
        if (props.options) {
            const nextItems: CustomOption[] = props.options?.map((opt) => ({
                ...opt,
                selected:
                    props.selectedOptions && props.selectedOptions.length > 0
                        ? !!props.selectedOptions.find(
                              (o) => opt.value === o.value,
                          )
                        : false,
            }));
            setItems(nextItems);
        } else {
            setItems([]);
        }
    }, [props.options, props.selectedOptions]);

    return (
        <>
            <Dropdown
                placement="bottomLeft"
                arrow
                onOpenChange={handleOpenChange}
                open={open}
                trigger={['click']}
                dropdownRender={() => (
                    <div className={style.dropdown__container}>
                        <Input
                            autoFocus
                            className={style.input}
                            placeholder={props.selectPlaceholder}
                            onChange={onSearch}
                            value={searchValue}
                            allowClear
                        />

                        <List>
                            {filteredItems?.length > 0 ? (
                                filteredItems.map((el) => (
                                    <List.Item
                                        key={el.value}
                                        onClick={() => onApplyFilters(el)}
                                        className={
                                            el.selected
                                                ? style.filled
                                                : style.row
                                        }
                                    >
                                        <List.Item.Meta
                                            title={el.label}
                                            description={
                                                el.value !== 'all' ? (
                                                    <Badge
                                                        color={getWorksheetStatusBadge(
                                                            el.status,
                                                        )}
                                                        text={getWorksheetStatusLabel(
                                                            el.status,
                                                            user?.role,
                                                        )}
                                                    />
                                                ) : (
                                                    ''
                                                )
                                            }
                                        />
                                    </List.Item>
                                ))
                            ) : (
                                <Typography.Text>
                                    {t('journalistSelect.noResults')}
                                </Typography.Text>
                            )}
                        </List>
                    </div>
                )}
            >
                <div
                    className={style.filter__closed}
                    onClick={() => setOpen(true)}
                >
                    <Typography.Text className={style.placeholder}>
                        {props.selectedOptions[0].label}
                        {props.selectedOptions[0].value !== 'all' && (
                            <Badge
                                color={getWorksheetStatusBadge(
                                    props.selectedOptions[0].status,
                                )}
                                text={getWorksheetStatusLabel(
                                    props.selectedOptions[0].status,
                                    user?.role,
                                )}
                                style={{ marginLeft: 10 }}
                            />
                        )}
                    </Typography.Text>
                    <CaretDownOutlined className={style.icon} />
                </div>
            </Dropdown>
        </>
    );
};

export default JournalistSelect;
