import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useFormik } from 'formik';

import { NetworkError } from 'network';

import { notification } from 'antd';
import { FilterOptionDict } from 'utils/interfaces/types';
import { getAccounts } from 'network/services/accounts';
import { getArticles, getNewspapers } from 'network/services/properties';
import { editWork, EditWorkParams, getWorkById } from 'network/services/works';
import { Work, WorkStatus } from 'utils/interfaces/works';
import JournalistWorkForm from 'components/forms/JournalistWorkForm';
import { Role } from 'utils/interfaces/user';

const EditWork = () => {
    const { workId } = useParams();
    const [work, setWork] = useState<Work>();
    const [options, setOptions] = useState<FilterOptionDict>({});
    const [errors, setErrors] = useState<NetworkError>();
    const navigate = useNavigate();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onCancel = () => {
        navigate(-1);
    };

    const handleSubmit = (values: EditWorkParams) => {
        editWork(
            { ...values, id: work?.id, journalist: work?.journalist?.id },
            () => {
                notification.success({
                    message: t(
                        'works.journalist.notification.editWorkForm.message',
                    ),
                });
                onCancel();
            },
            onError,
        );
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: work?.date,
            description: work?.description,
            client_name: work?.client_name,
            client: work?.client?.id,
            code: work?.code,
            article: work?.article?.id,
            newspaper: work?.newspaper?.id,
            quantity: work?.quantity,
            cost: work?.cost,
        } as EditWorkParams,
        validateOnMount: true,
        onSubmit: (values) => handleSubmit(values),
    });

    useEffect(() => {
        getWorkById(
            Number(workId),
            (work) => {
                setWork(work);
            },
            () => console.log('error'),
        );

        getAccounts({ page_size: 1000, role: Role.Client }, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                client: items.map((el) => ({
                    value: el.id,
                    label: el.full_name,
                })),
            }));
        });

        getArticles(undefined, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                article: items.map((el) => ({
                    value: el.id,
                    label: el.value,
                })),
            }));
        });

        getNewspapers(undefined, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                newspaper: items.map((el) => ({
                    value: el.id,
                    label: el.value,
                })),
            }));
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeInput = (e: any) => {
        formik.handleChange(e);
    };

    const handleChangeSelect = (key: string, e: any) => {
        formik.setFieldValue(key, e);
    };

    const onOk = () => {
        formik.handleSubmit();
    };

    return (
        <JournalistWorkForm
            title={t('works.editWorkForm.title')}
            cancelText={t('works.editWorkForm.cancelText')}
            okText={t('works.editWorkForm.okText')}
            onOk={onOk}
            onCancel={onCancel}
            initialValues={formik.initialValues}
            values={formik.values}
            onChangeInput={handleChangeInput}
            onChangeSelect={handleChangeSelect}
            errors={errors}
            options={options}
            readOnly={
                work?.status === WorkStatus.Sent ||
                work?.status === WorkStatus.Validated ||
                work?.status === WorkStatus.Approved
            }
        />
    );
};

export default EditWork;
