import { createSlice } from '@reduxjs/toolkit';
import { logoutService } from 'network/services/auth';
import { User } from 'utils/interfaces/user';

interface IUserState {
    currentUser?: User;
}

const initialState: IUserState = {
    currentUser: undefined,
};

const UserSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setCurrentUser(state, action) {
            state.currentUser = action.payload;
        },
        logout: (state) => {
            logoutService(
                () => {
                    localStorage.clear();
                    localStorage.removeItem('auth-token'); // delete token from storage
                },
                () => console.log('error'),
            );
        },
    },
});

export const { setCurrentUser, logout } = UserSlice.actions;

export default UserSlice;
