import callAPI, { NetworkError } from 'network';
import { User } from 'utils/interfaces/user';

// authenticated
export const changePasswordService = async (
    new_password1: string,
    new_password2: string,
    onSuccess: (user: User) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/auth/password/change';
    const data = {
        new_password1,
        new_password2,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

// authenticated
export const changePasswordWithTokenService = async (
    new_password1: string,
    new_password2: string,
    uid: string,
    token: string,
    onSuccess: (user: User) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/auth/password/reset/confirm';
    let data = {
        new_password1,
        new_password2,
        uid,
        token,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

// not authenticated
export const resetPasswordService = async (
    email: string,
    onSuccess: () => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/auth/password/reset';
    const data = {
        email,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess();
    } else {
        onError(response.errors!);
    }
};
