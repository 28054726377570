import { TextAreaProps } from "antd/lib/input";
import TextArea from "antd/lib/input/TextArea";
import { NetworkError } from "network";
import InputWrapper from "./InputWrapper";

export interface InputProps extends TextAreaProps{
    id: string;
    label: string;
    errors: NetworkError | undefined;
    containerStyle?: string;
    tooltip?: string;
    optional?: boolean;
}

const TextAreaInput = (props: InputProps) => {
    return (
        <InputWrapper
            id={props.id}
            label={props.label}
            containerStyle={props.containerStyle}
            errors={props.errors}
            tooltip={props.tooltip}
            optional={props.optional}
        >
            <TextArea {...props} />
        </InputWrapper>
    );
};
export default TextAreaInput;

