import { logo } from 'assets/images';

import style from './Loading.module.scss';
import { constants } from 'utils/constants/constants';

const Loading = () => {
    return (
        <div className={style.loading__container}>
            <img alt={constants.appName} src={logo} />
            <p>Loading....</p>
        </div>
    );
};

export default Loading;
