import { Typography } from 'antd';
import ResultPage from 'components/result/ResultPage';
import { t } from 'i18next';
import { resetPasswordService } from 'network/services/password';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'router/routes';

const ForgottenPasswordSuccess = () => {
    const navigate = useNavigate();
    const params = useLocation();

    const onLogin = () => {
        navigate(PublicRoutes.BASE);
    };

    useEffect(() => {
        if (!params.state) {
            navigate(PublicRoutes.BASE);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const onResentEmail = () => {
        if (!params.state.email) {
            navigate(PublicRoutes.BASE);
        }
        resetPasswordService(
            params.state.email,
            () => {
                console.log('result');
            },
            () => {
                console.log('error');
            },
        );
    };
    return (
        <ResultPage
            status="success"
            title={t('forgottenPassword.success.title')}
            subTitle={t('forgottenPassword.success.subtitle')}
            extra={[
                <Typography.Link underline onClick={onResentEmail}>
                   {t('forgottenPassword.success.buttons.resend')}
                </Typography.Link>,
                <Typography.Link underline onClick={onLogin}>
                   {t('forgottenPassword.success.buttons.back')}
                </Typography.Link>,
            ]}
        />
    );
};

export default ForgottenPasswordSuccess;
