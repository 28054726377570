import { Period } from './period';
import { User } from './user';

export enum WorksheetStatus {
    Draft = 'draft', // grigio
    Sent = 'sent', // arancio
    Approved = 'approved', // verde
    Validated = 'validated', // blue
}

export interface Worksheet {
    id: number;
    period: Period;
    journalist: User;
    status: WorksheetStatus;
    created_at?: string;
    updated_at?: string;
    works_count?: number;
    export_url?: string;
    exported_at?: string;
}
