import { Space } from 'antd';
import Search from 'antd/lib/input/Search';
import Button from 'components/buttons/Button';
import { t } from 'i18next';
import { Fragment } from 'react';
import { ActionBar } from 'utils/interfaces/action-bar';
import style from './TableActionBar.module.scss';

export interface TableActionBarProps {
    searchPlaceholder: string;
    selectedRowsLabel?: string;
    onSearch: (value: string) => void;
    selectedRowKeys?: React.Key[];
    actionsBar?: ActionBar[];
    searchValue: string | null;
    enableActions?: boolean;
}

const TableActionBar = (props: TableActionBarProps) => {
    const hasSelected = props.selectedRowKeys
        ? props.selectedRowKeys.length > 0
        : false;

    const enableActions = props.enableActions || true;

    return (
        <div className={style.actionsBar__container}>
            <Search
                value={props.searchValue || undefined}
                placeholder={props.searchPlaceholder}
                onSearch={(value: string) => props.onSearch(value)}
                className={style.actionsBar__search}
                allowClear
            />
            {enableActions && (
                <Space className={style.actionsBar__buttons}>
                    {hasSelected &&
                        t(props.selectedRowsLabel || '', {
                            count:
                                props.selectedRowKeys &&
                                props.selectedRowKeys.length,
                        })}
                    {props.actionsBar &&
                        props.actionsBar.map((el) => (
                            <Fragment key={el.label}>
                                {!el.hidden && (
                                    <Button
                                        key={el.label}
                                        type="link"
                                        onClick={el.action}
                                        label={el.label}
                                        disabled={el.disabled}
                                        danger={el.danger}
                                        icon={<el.icon />}
                                    />
                                )}
                            </Fragment>
                        ))}
                </Space>
            )}
        </div>
    );
};

export default TableActionBar;
