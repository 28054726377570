import { t } from 'i18next';
import { Form, Formik } from 'formik';
import { Space } from 'antd';

import TextInput from 'components/inputs/TextInput';
import Modal from 'components/modal/Modal';
import DateInput from 'components/inputs/DateInput';
import SingleSelect from 'components/inputs/SingleSelect';
import NumberInput from 'components/inputs/NumberInput';

import { CreateWorkParams, EditWorkParams } from 'network/services/works';
import { NetworkError } from 'network';

import { FilterOptionDict } from 'utils/interfaces/types';
import style from './WorkForm.module.scss';
import TextAreaInput from 'components/inputs/TextAreaInput';

export interface WorkFormProps {
    title: string;
    cancelText: string;
    okText: string;
    onOk: () => void;
    onCancel: () => void;
    initialValues: CreateWorkParams | EditWorkParams;
    values: CreateWorkParams | EditWorkParams;
    onChangeSelect: (key: string, e: any) => void;
    onChangeInput: (e: any) => void;
    errors: NetworkError | undefined;
    options: FilterOptionDict;
    readOnly?: boolean;
}

const ClientWorkForm = (props: WorkFormProps) => {
    return (
        <Formik initialValues={props.initialValues} onSubmit={props.onOk}>
            <Form>
                <Modal
                    title={props.title}
                    open
                    onCancel={props.onCancel}
                    onOk={props.onOk}
                    cancelText={props.cancelText}
                    okText={props.okText}
                    okButtonProps={{ disabled: props.readOnly}}
                >
                    <DateInput
                        id="date"
                        label={t('works.workForm.date.label')}
                        onChange={(e) => props.onChangeSelect('date', e)}
                        value={props.values.date}
                        errors={props.errors}
                        containerStyle={style.newaccountform__input}
                        innerStyle={style.wrapper__fullWidth}
                        tooltip={t('works.workForm.date.tooltip') || ''}
                        disabled={props.readOnly}
                    />
                    <SingleSelect
                        id="journalist"
                        label={t('works.workForm.journalist.label')}
                        placeholder={t('works.workForm.journalist.placeholder')}
                        onChange={(e) => props.onChangeSelect('journalist', e)}
                        value={props.values.journalist}
                        errors={props.errors}
                        containerStyle={style.newaccountform__input}
                        innerStyle={style.wrapper__fullWidth}
                        options={props.options['journalist']}
                        tooltip={t('works.workForm.journalist.tooltip') || ''}
                        disabled={props.readOnly}
                    />
                    <TextInput
                        id="client_name"
                        label={t('works.workForm.client_name.label')}
                        placeholder={
                            t('works.workForm.client_name.placeholder') || ''
                        }
                        onChange={props.onChangeInput}
                        value={props.values.client_name}
                        errors={props.errors}
                        containerStyle={style.newaccountform__input}
                        tooltip={t('works.workForm.client_name.tooltip') || ''}
                        optional={true}
                        disabled={props.readOnly}
                    />
                    <SingleSelect
                        id="article"
                        label={t('works.workForm.article.label')}
                        placeholder={t('works.workForm.article.placeholder')}
                        onChange={(e) => props.onChangeSelect('article', e)}
                        value={props.values.article}
                        errors={props.errors}
                        containerStyle={style.newaccountform__input}
                        innerStyle={style.wrapper__fullWidth}
                        options={props.options['article']}
                        tooltip={t('works.workForm.article.tooltip') || ''}
                        disabled={props.readOnly}
                    />
                    <TextAreaInput
                        id="description"
                        label={t('works.workForm.description.label')}
                        placeholder={
                            t('works.workForm.description.placeholder') || ''
                        }
                        onChange={props.onChangeInput}
                        value={props.values.description}
                        errors={props.errors}
                        containerStyle={style.newaccountform__input}
                        tooltip={t('works.workForm.description.tooltip') || ''}
                        disabled={props.readOnly}
                    />

                    <TextInput
                        id="code"
                        label={t('works.workForm.code.label')}
                        placeholder={t('works.workForm.code.placeholder') || ''}
                        onChange={props.onChangeInput}
                        value={props.values.code}
                        errors={props.errors}
                        containerStyle={style.newaccountform__input}
                        tooltip={t('works.workForm.code.tooltip') || ''}
                        disabled={props.readOnly}
                    />
                    <SingleSelect
                        id="newspaper"
                        label={t('works.workForm.newspaper.label')}
                        placeholder={t('works.workForm.newspaper.placeholder')}
                        onChange={(e) => props.onChangeSelect('newspaper', e)}
                        value={props.values.newspaper}
                        errors={props.errors}
                        containerStyle={style.newaccountform__input}
                        innerStyle={style.wrapper__fullWidth}
                        options={props.options['newspaper']}
                        tooltip={t('works.workForm.newspaper.tooltip') || ''}
                        disabled={props.readOnly}
                    />

                    <Space
                        direction="horizontal"
                        className={style.newwork__row}
                    >
                        <NumberInput
                            id="quantity"
                            label={t('works.workForm.quantity.label')}
                            placeholder={
                                t('works.workForm.quantity.placeholder') || ''
                            }
                            onChange={(e) =>
                                props.onChangeSelect('quantity', e)
                            }
                            onStep={(value) =>
                                props.onChangeSelect('quantity', value)
                            }
                            value={props.values.quantity}
                            errors={props.errors}
                            containerStyle={style.newaccountform__input}
                            min={0.1}
                            step={0.1}
                            controls
                            addonBefore="U"
                            tooltip={t('works.workForm.quantity.tooltip') || ''}
                            disabled={props.readOnly}
                        />
                        <NumberInput
                            id="cost"
                            label={t('works.workForm.cost.label')}
                            placeholder={
                                t('works.workForm.cost.placeholder') || ''
                            }
                            onChange={(e) => props.onChangeSelect('cost', e)}
                            onStep={(value) =>
                                props.onChangeSelect('cost', value)
                            }
                            value={props.values.cost}
                            errors={props.errors}
                            containerStyle={style.newaccountform__input}
                            addonBefore="€"
                            min={0.01}
                            step={0.01}
                            controls
                            tooltip={t('works.workForm.cost.tooltip') || ''}
                            disabled={props.readOnly}
                        />
                    </Space>
                </Modal>
            </Form>
        </Formik>
    );
};

export default ClientWorkForm;
