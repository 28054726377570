import i18next from 'i18next';
import moment from 'moment';

export const getLocaleDateString = (date: Date | string | undefined) => {
    if (!date) {
        return '';
    }
    return new Date(date).toLocaleDateString(i18next.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
};

export const getDifferenceBetweenDates = (
    _start: string | undefined,
    _end: string | undefined,
) => {
    const start = moment(_start).startOf('day');
    const end = moment(_end);

    return start.diff(end, 'days');
};
