import callAPI, { NetworkError } from 'network';
import { constants } from 'utils/constants/constants';
import { Account, AccountStatus } from 'utils/interfaces/account';
import { Role } from 'utils/interfaces/user';

export const getAccounts = async (
    params: any,
    onSuccess: (accounts: Account[], totalItems: number | undefined) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/accounts';

    const nextParams = {
        page_size: constants.table.pageSize,
        ...params,
    };

    // Rewrite custom fields
    if (nextParams['ordering'] === 'full_name') nextParams['ordering'] = 'first_name,last_name'
    if (nextParams['ordering'] === '-full_name') nextParams['ordering'] = '-first_name,-last_name'
    if (nextParams['ordering'] === 'status') nextParams['ordering'] = 'is_active,is_enrolled'
    if (nextParams['ordering'] === '-status') nextParams['ordering'] = '-is_active,-is_enrolled'

    if (nextParams['status'] === AccountStatus.Active) { nextParams['is_active'] = true;  nextParams['is_enrolled'] = true; } 
    else if (nextParams['status'] === AccountStatus.Inactive) nextParams['is_active'] = false; 
    else if (nextParams['status'] === AccountStatus.Waiting) nextParams['is_enrolled'] = false; 
    delete nextParams['status'];
    // End of Rewrite

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        nextParams,
        false,
    );

    if (response.data) {
        onSuccess(response.data, response.totalItems);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const createAccount = async (
    email: string,
    first_name: string,
    last_name: string,
    role: Role,
    onSuccess: (account: Account[]) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/accounts';
    const data = {
        email,
        first_name,
        last_name,
        role,
    };
    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

export const deleteAccount = async (
    id: number,
    onSuccess: () => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'delete';
    const endpoint = '/api/accounts/' + id;
    const data = {
        id,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data || response.status === 204) { // data = '' if status = 204
        onSuccess();
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const deactiveAccount = async (
    id: number,
    onSuccess: (account: Account) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/accounts/' + id + '/deactivate';
    const data = {
        id,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const reactiveAccount = async (
    id: number,
    onSuccess: (account: Account) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/accounts/' + id + '/reactivate';
    const data = {
        id,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const resendEmail = async (
    id: number,
    onSuccess: (account: Account) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/accounts/' + id + '/resend';
    const data = {
        id,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};
