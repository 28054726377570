import { Badge, Table, TablePaginationConfig } from 'antd';
import TableActionBar from 'components/bars/TableActionBar';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { t } from 'i18next';
import { TableParams } from 'utils/interfaces/pagination';
import Link from 'antd/lib/typography/Link';
import { TableWrapper } from 'components/wrapper/TableWrapper';
import { UserAvatar } from 'components/avatar/UserAvatar';
import { SendOutlined } from '@ant-design/icons/lib/icons';
import { Worksheet, WorksheetStatus } from 'utils/interfaces/worksheets';
import { getLocaleDateString } from 'utils/date';
import { useAppSelector } from 'store/hook';
import {
    getWorksheetStatusBadge,
    getWorksheetStatusLabel,
} from 'utils/worksheets';
import { worksheetsColumnsKey } from 'utils/constants/table';
import { createColumn } from 'utils/table';
import { BadgeStatus } from 'utils/interfaces/badge';

interface WorksheetsTableProps {
    worksheets: Worksheet[];
    tableParams: TableParams;
    setTableParams: Dispatch<SetStateAction<TableParams>>;
    searchParams: URLSearchParams;
    onAddParams: (params: {
        [x: string]: string | boolean | number | undefined;
    }) => void;
    resetFilters: () => void;
    onOpenWork: (worksheetId?: number) => void;
    onExportWorksheet: (url?: string) => void;
}

const WorksheetsTable = (props: WorksheetsTableProps) => {
    const user = useAppSelector((state) => state.user.currentUser);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedWorksheet, setSelectedWorksheet] = useState<Worksheet[]>([]);

    const columns: any = useMemo(() => {
        return worksheetsColumnsKey.map((el) =>
            createColumn(
                'worksheets.table.columns',
                el,
                props.tableParams.sortField,
                props.tableParams.sortOrder,
            ),
        );
    }, [props.tableParams]);

    const actionsBar = useMemo(
        () =>
            selectedRowKeys.length > 0
                ? [
                      {
                          key: t('worksheets.table.actionsBar.send_email'),
                          icon: SendOutlined,
                          label: t('worksheets.table.actionsBar.send_email'),
                          action: () =>
                              (window.location.href = `mailto:${selectedWorksheet[0].journalist.email}`),
                          danger: false,
                          hidden: false,
                      },
                  ]
                : [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedRowKeys],
    );

    const dataSource = useMemo(() => {
        return props.worksheets.map((w) => ({
            key: w.id,
            open: (
                <Link onClick={() => props.onOpenWork(w?.id)}>
                    {t('worksheets.table.columns.open')}
                </Link>
            ),

            journalist: <UserAvatar user={w.journalist} color="secondary" />,
            created_at: getLocaleDateString(w?.created_at),
            updated_at: getLocaleDateString(w?.updated_at),
            works_count: w?.works_count,
            status: (
                <Badge
                    color={getWorksheetStatusBadge(w.status)}
                    text={getWorksheetStatusLabel(w.status, user?.role)}
                />
            ),
            exported_at: (
                <>
                    {w.status !== WorksheetStatus.Approved ? (
                        <>
                            {t(
                                'worksheets.table.actionsBar.export_not_available',
                            )}
                        </>
                    ) : (
                        <>
                            {w?.exported_at === null ? (
                                <Link
                                    onClick={() => {
                                        props.onExportWorksheet(w?.export_url);
                                    }}
                                >
                                    {t('works.manager.layout.buttons.export')}
                                </Link>
                            ) : (
                                <Badge
                                    color={BadgeStatus.Success}
                                    text={t(
                                        'worksheets.table.actionsBar.exported_at',
                                        {
                                            date: getLocaleDateString(
                                                w?.exported_at,
                                            ),
                                        },
                                    )}
                                />
                            )}
                        </>
                    )}
                </>
            ),
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.worksheets]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const lastElement = newSelectedRowKeys[newSelectedRowKeys.length - 1];
        const nextSelected = props.worksheets.filter(
            (el) => el.id === lastElement,
        );
        setSelectedRowKeys(lastElement ? [lastElement] : []);
        setSelectedWorksheet(nextSelected);
    };

    const rowSelection = {
        selectedRowKeys,
        hideSelectAll: true,
        onChange: onSelectChange,
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: any,
    ) => {
        const ordering = sorter.order
            ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
            : '';
        props.setTableParams({
            ...sorter,
            pagination,
        });
        props.onAddParams({ page: pagination.current, ordering: ordering });
    };

    return (
        <TableWrapper id="client-works-table">
            <TableActionBar
                searchPlaceholder={t('worksheets.table.search')}
                onSearch={(value) =>
                    props.onAddParams({ search: value, page: 1 })
                }
                searchValue={props.searchParams.get('search') || ''}
                actionsBar={selectedRowKeys.length > 0 ? actionsBar : []}
                selectedRowKeys={selectedRowKeys}
                selectedRowsLabel={
                    t('worksheets.table.actionsBar.selectedWorks') || ''
                }
            />
            <Table
                dataSource={dataSource}
                rowSelection={rowSelection}
                columns={columns}
                onChange={handleTableChange}
                showSorterTooltip={false}
                pagination={{
                    ...props.tableParams.pagination,
                    position: ['bottomCenter'],
                }}
                scroll={{ x: 'max-content' }}
                footer={() => (
                    <>
                        {dataSource.length > 1 && (
                            <Link onClick={() => props.onOpenWork()}>
                                {t('worksheets.table.footer')}
                            </Link>
                        )}
                    </>
                )}
            />
        </TableWrapper>
    );
};

export default WorksheetsTable;
