import { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import {
    deactiveAccount,
    deleteAccount,
    reactiveAccount,
    resendEmail,
} from 'network/services/accounts';
import { getAccounts } from 'network/services/accounts';
import { Account } from 'utils/interfaces/account';
import Button from 'components/buttons/Button';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { PrivateRoutes } from 'router/routes';
import { Modal, notification, PageHeader } from 'antd';
import { TableParams } from 'utils/interfaces/pagination';
import AccountsTable, { AccountsTableRefs } from './AccountsTable';
import { t } from 'i18next';
import { constants } from 'utils/constants/constants';
import { parseOrderingFromUrl } from 'utils/table';
import AccountsFiltersTab from './AccountsFiltersTab';

const Accounts = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [accounts, setAccounts] = useState<Account[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            size: 'small',
            pageSize: constants.table.pageSize,
            hideOnSinglePage: true,
        },
    });

    const tableRef = useRef<AccountsTableRefs>(null);

    const handleAddUser = () => {
        navigate({
            pathname: PrivateRoutes.NEW,
            search: searchParams.toString(),
        });
    };

    const onResendEmailToAccount = (account: Account | undefined) => {
        if (!account) {
            return;
        }
        resendEmail(account.id, () => {
            notification.success({
                message: t('accounts.notification.resendEmail.message'),
                description: t('accounts.notification.resendEmail.description'),
            });
            tableRef.current?.resetSelection();
        });
    };

    const onReactiveAccount = (account: Account | undefined) => {
        if (!account) {
            return;
        }
        reactiveAccount(account.id, (newAccount) => {
            setAccounts((prev) =>
                prev.map((el) => (el.id === account.id ? newAccount : el)),
            );
            notification.success({
                message: t('accounts.notification.reactiveAccount.message'),
                description: t(
                    'accounts.notification.reactiveAccount.description',
                ),
            });
            tableRef.current?.resetSelection();
        });
    };

    const handleDeactiveAccount = (id: number) => {
        deactiveAccount(id, (newAccount) => {
            setAccounts((prev) =>
                prev.map((el) => (el.id === id ? newAccount : el)),
            );
            notification.success({
                message: t('accounts.notification.deactiveUser.message'),
                description: t(
                    'accounts.notification.deactiveUser.description',
                ),
            });
            tableRef.current?.resetSelection();
        });
    };

    const onDeactiveAccount = (account: Account | undefined) => {
        if (!account) {
            return;
        }
        Modal.confirm({
            title: t('accounts.modals.deactiveUser.title', {
                full_name: account.full_name,
            }),
            content: t('accounts.modals.deactiveUser.content'),
            okText: t('accounts.modals.deactiveUser.okText'),
            cancelText: t('accounts.modals.deactiveUser.cancelText'),
            onOk: () => handleDeactiveAccount(account.id),
        });
    };

    const handleDeleteAccount = (account: Account) => {
        deleteAccount(account.id, () => {
            setAccounts(accounts.filter((el) => el.id !== account.id));
            notification.success({
                message: t('accounts.notification.deleteUser.message'),
                description: t('accounts.notification.deleteUser.description'),
            });
            tableRef.current?.resetSelection();
        });
    };

    const onDeleteAccount = (account: Account | undefined) => {
        if (!account) {
            return;
        }
        Modal.confirm({
            title: t('accounts.modals.deleteUser.title', {
                full_name: account.full_name,
            }),
            content: t('accounts.modals.deleteUser.content'),
            okText: t('accounts.modals.deleteUser.okText'),
            cancelText: t('accounts.modals.deleteUser.cancelText'),
            onOk: () => handleDeleteAccount(account),
        });
    };

    const onGetAccounts = (params: any) => {
        getAccounts(
            params,
            (accs, totalItems) => {
                setAccounts(accs);
                setTableParams((tableParams) => {
                    return {
                        sortOrder: parseOrderingFromUrl(params.ordering)
                            .sortOrder,
                        sortField: parseOrderingFromUrl(params.ordering)
                            .sortField,
                        pagination: {
                            ...tableParams.pagination,
                            pageSize: constants.table.pageSize,
                            current: parseInt(searchParams.get('page') || '1'),
                            total: totalItems,
                        },
                    };
                });
            },
            (error) => console.log('error', error),
        );
    };

    useEffect(() => {
        const params: { [x: string]: string } = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
        });
        onGetAccounts(params);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, navigate]);

    const onAddParams = (params: {
        [x: string]: string | boolean | number | undefined;
    }) => {
        for (const [key, value] of Object.entries(params)) {
            searchParams.set(key, value?.toString() ?? '');
        }
        setSearchParams(searchParams);
    };

    const resetFilters = () => {
        setSearchParams();
    };

    return (
        <>
            <PageHeader
                title={t('accounts.layout.title')}
                extra={[
                    <Button
                        key="add-user-button"
                        type="primary"
                        onClick={handleAddUser}
                        label={t('accounts.layout.buttons.addUser')}
                        icon={<PlusOutlined />}
                    />,
                ]}
            />
            <AccountsFiltersTab
                searchParams={searchParams}
                onAddParams={onAddParams}
                onResetFilters={resetFilters}
            />
            <AccountsTable
                ref={tableRef}
                accounts={accounts}
                onAddParams={onAddParams}
                onResendEmailToAccount={onResendEmailToAccount}
                onReactiveAccount={onReactiveAccount}
                onDeactiveAccount={onDeactiveAccount}
                onDeleteAccount={onDeleteAccount}
                tableParams={tableParams}
                setTableParams={setTableParams}
                searchParams={searchParams}
            />
            <Outlet />
        </>
    );
};

export default Accounts;
