import { Outlet, useNavigate } from 'react-router-dom';
import { PrivateRoutes } from 'router/routes';
import { useEffect } from 'react';
import { getActivePeriod } from 'network/services/periods';
import { replaceUrlString } from 'utils/string';
import { Role } from 'utils/interfaces/user';
import { useAppSelector } from 'store/hook';

const Homepage = () => {
    const user = useAppSelector((state) => state.user.currentUser);

    const navigate = useNavigate();

    useEffect(() => {
        getActivePeriod((data) => {
            if (user?.role === Role.Manager) {
                navigate(
                    replaceUrlString(PrivateRoutes.WORKSHEETS, {
                        periodId: data.id,
                    }),
                );
            } else {
                navigate(
                    replaceUrlString(PrivateRoutes.WORKS, {
                        periodId: data.id,
                    }),
                );
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Outlet />;
};

export default Homepage;
