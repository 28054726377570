import callAPI, { NetworkError } from 'network';
import { Key } from 'react';
import { Work } from 'utils/interfaces/works';

export interface CreateWorkParams {
    date: string;
    description: string;
    journalist?: string | number;
    client?: string | number;

    newspaper?: string;
    article?: string;

    client_name?: string;
    code?: string;
    quantity?: number;
    cost?: number;
}

export interface EditWorkParams extends CreateWorkParams {
    id?: number;
}

export const createWork = async (
    params: CreateWorkParams,
    onSuccess: (account: Work[]) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/works';

    console.log(params);

    const data = {
        ...params,
        client: {
            id: params.client,
        },
        journalist: {
            id: params.journalist,
        },
        article: {
            id: params?.article,
        },
        newspaper: {
            id: params?.newspaper,
        },
        cost: params?.cost ? +params.cost : null,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

export const editWork = async (
    params: EditWorkParams,
    onSuccess: (account: Work[]) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'put';
    const endpoint = '/api/works/' + params.id;

    const data = {
        ...params,
        client: {
            id: params.client,
        },
        journalist: {
            id: params.journalist,
        },
        article: {
            id: params?.article,
        },
        newspaper: {
            id: params?.newspaper,
        },

        cost: params?.cost ? +params.cost : null,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

export const getWorkById = async (
    id: number,
    onSuccess: (work: Work) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/works/' + id;

    const data = {
        id,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

export const approveWorks = async (
    ids: Key[],
    onSuccess: (work: Work) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/works/approve';

    const data = ids.map((el) => ({ id: el }));
    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const validateWorks = async (
    ids: Key[],
    onSuccess: (work: Work) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/works/validate';

    const data = ids.map((el) => ({ id: el }));
    const response = await callAPI(method, endpoint, data, undefined, false);
    console.log('response', response);

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const deleteWorks = async (
    ids: Key[],
    onSuccess: () => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'delete';
    const endpoint = '/api/works/bulk_destroy/';
    const data = ids.map((el) => ({ id: el }));

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data || response.status === 204) {
        // data = '' if status = 204
        onSuccess();
    }
    if (onError) {
        onError(response.errors!);
    }
};
