export const constants = {
    appName: "Digital 360",
    supportMail: "contentoda@digital360.it",
    login: {
        passwordMinLength: 8,
        // passwordMaxLength: 50,
        emailMinLength: 2,
        //emailMaxLength: 100,
    },
    table: {
        pageSize: 100,
    },
    date: {
        itFormat: 'DD/MM/YYYY',
        backendFormat: 'YYYY-MM-DD',
    },
    showSendWork: -2,  // mostra "invia consuntivo" x giorni prima della chiusura
    hideSendWork: 99, // nascondi "invia consuntivo" x giorni dalla chiusura
    showAddJournalistWork: 10, // nascondi “aggiungi inserimento” dopo x giorni dalla chiusura
    showAddClientWork: 99, // nascondere “aggiungi commessa” dopo x giorni dalla chiusura
};