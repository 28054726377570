import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import style from './Button.module.scss';

interface ButtonProps extends AntButtonProps {
    label: string;
    className?: string;
}

const Button = (props: ButtonProps) => (
    <AntButton {...props} className={`${style.button} ${props.className}`}>
        {props.label}
    </AntButton>
);

export default Button;
