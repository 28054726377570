import { Key, useEffect, useMemo, useState, Fragment, useRef } from 'react';
import {
    Outlet,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { t } from 'i18next';

import {
    Badge,
    Modal,
    notification,
    PageHeader,
    Select,
    Typography,
} from 'antd';
import { PlusOutlined, SendOutlined } from '@ant-design/icons/lib/icons';

import Button from 'components/buttons/Button';
import WorksTable from './WorksTable';
import WorksFiltersTab from './WorksFiltersTab';

import {
    getPeriods,
    getWorksByPeriod,
    getWorksheetsByPeriod,
} from 'network/services/periods';

import { sendWorksheet } from 'network/services/worksheets';
import { deleteWorks } from 'network/services/works';

import { Worksheet, WorksheetStatus } from 'utils/interfaces/worksheets';
import { Period } from 'utils/interfaces/period';
import { Work, WorksTableRefs } from 'utils/interfaces/works';
import { replaceUrlString } from 'utils/string';
import { TableParams } from 'utils/interfaces/pagination';
import { constants } from 'utils/constants/constants';
import { ParamsDict, StringDict } from 'utils/interfaces/types';
import { parseOrderingFromUrl } from 'utils/table';

import { PrivateRoutes, PublicRoutes } from 'router/routes';
import { useAppSelector } from 'store/hook';
import { getDifferenceBetweenDates } from 'utils/date';
import {
    getCurrentWorksheetDeadlineColor,
    getWorksheetStatusBadge,
    getWorksheetStatusLabel,
} from 'utils/worksheets';
import { getCurrentWorkDeadline } from 'utils/works';

// JOURNALIST WORKS
const Works = () => {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.user.currentUser);

    const { periodId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const tableRef = useRef<WorksTableRefs>(null);

    const [works, setWorks] = useState<Work[]>([]);
    const [worksheets, setWorksheets] = useState<Worksheet[]>([]);
    const [periods, setPeriods] = useState<Period[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            size: 'small',
            pageSize: constants.table.pageSize,
            hideOnSinglePage: true,
        },
    });

    const onAddWork = () => {
        tableRef.current?.resetSelection();
        navigate({
            pathname: PrivateRoutes.NEW,
            search: searchParams.toString(),
        });
    };

    const onSelectPeriod = (value: string) => {
        tableRef.current?.resetSelection();
        navigate(replaceUrlString(PrivateRoutes.WORKS, { periodId: value }), {
            replace: true,
        });
    };

    const onEditWork = (id: number) => {
        tableRef.current?.resetSelection();
        navigate(
            replaceUrlString(PrivateRoutes.EDIT_WORK, {
                workId: id.toString(),
            }),
        );
    };

    const onGetWorksheetByPeriod = (id: string) => {
        getWorksheetsByPeriod(
            id,
            {},
            (items) => {
                setWorksheets(items);
            },
            (error) => console.log('error', error),
        );
    };

    const onGetWorksByPeriod = (id: string, params: any) => {
        getWorksByPeriod(
            id,
            params,
            (items, totalItems) => {
                setWorks(items);
                setTableParams((tableParams) => {
                    return {
                        sortOrder: parseOrderingFromUrl(params.ordering)
                            .sortOrder,
                        sortField: parseOrderingFromUrl(params.ordering)
                            .sortField,
                        pagination: {
                            ...tableParams.pagination,
                            pageSize: constants.table.pageSize,
                            current: parseInt(searchParams.get('page') || '1'),
                            total: totalItems,
                        },
                    };
                });
            },
            (error) => console.log('error', error),
        );
    };

    const onAddParams = (params: ParamsDict) => {
        for (const [key, value] of Object.entries(params)) {
            searchParams.set(key, value?.toString() ?? '');
        }
        setSearchParams(searchParams);
    };

    const resetFilters = () => {
        setSearchParams();
    };

    const onDeleteWorks = (ids: Key[]) => {
        if (!ids || ids.length === 0) {
            return;
        }
        Modal.confirm({
            title: t('works.journalist.modals.deleteWorks.title'),
            content: t('works.journalist.modals.deleteWorks.content'),
            okText: t('works.journalist.modals.deleteWorks.okText'),
            cancelText: t('works.journalist.modals.deleteWorks.cancelText'),
            onOk: () => handleDeleteWorks(ids),
        });
    };

    const handleDeleteWorks = (ids: Key[]) => {
        deleteWorks(ids, () => {
            notification.success({
                message: t('works.journalist.notification.deleteWorks.message', {
                    count: ids.length,
                }),
            });
            tableRef.current?.resetSelection();
            setWorks((previousWorks) =>
                previousWorks.filter((el) => !ids.includes(el.id)),
            );
        });
    };

    const onHandleSendWork = () => {
        Modal.confirm({
            title: t('works.journalist.modals.sendWork.title'),
            content: t('works.journalist.modals.sendWork.content'),
            okText: t('works.journalist.modals.sendWork.okText'),
            cancelText: t('works.journalist.modals.sendWork.cancelText'),
            onOk: () => onSendWork(),
        });
    };

    const onSendWork = () => {
        sendWorksheet(
            worksheets[0].id,
            (response) => {
                onGetWorksheetByPeriod(periodId as string);
                tableRef.current?.resetSelection();
            },
            (error) => console.log('error', error),
        );
    };

    useEffect(() => {
        const params: StringDict = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
        });
        if (!periodId) {
            navigate(PublicRoutes.BASE);
        }
        onGetWorksByPeriod(periodId as string, params);
        onGetWorksheetByPeriod(periodId as string);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, periodId, searchParams]);

    useEffect(() => {
        getPeriods((data) => setPeriods(data));
    }, []);

    const currentPeriod = useMemo(
        () =>
            periods.length > 0
                ? periods?.find((el) => el.id.toString() === periodId)
                : undefined,
        [periodId, periods],
    );

    const showDeadline = useMemo(
        () =>
            worksheets.length === 0 ||
            worksheets[0]?.status === WorksheetStatus.Draft,
        [worksheets],
    );

    const showSendWork = useMemo(
        () =>
            currentPeriod?.end &&
            getDifferenceBetweenDates(undefined, currentPeriod?.end) >=
                constants.showSendWork &&
            getDifferenceBetweenDates(undefined, currentPeriod?.end) <=
                constants.hideSendWork &&
            worksheets.length > 0 &&
            worksheets[0]?.status === WorksheetStatus.Draft
                ? true
                : false,
        [currentPeriod?.end, worksheets],
    );

    const showAddWork = useMemo(
        () =>
            currentPeriod?.end &&
            getDifferenceBetweenDates(undefined, currentPeriod?.end) <=
                constants.showAddJournalistWork &&
            (worksheets.length === 0 ||
                worksheets[0]?.status === WorksheetStatus.Draft)
                ? true
                : false,

        [currentPeriod?.end, worksheets],
    );

    return (
        <>
            <PageHeader
                title={t('works.journalist.layout.title')}
                subTitle={
                    <>
                        {periods?.length > 0 && (
                            <Select
                                key="select-period-dropdown"
                                defaultValue={currentPeriod?.name}
                                options={periods?.map((el) => ({
                                    value: el.id,
                                    label: el.name,
                                }))}
                                style={{ width: 170 }}
                                onSelect={(opt: any) => onSelectPeriod(opt)}
                            />
                        )}
                        <Badge
                            key="worksheet-status"
                            color={getWorksheetStatusBadge(
                                worksheets[0]?.status,
                            )}
                            text={getWorksheetStatusLabel(
                                worksheets[0]?.status,
                                user?.role,
                            )}
                            style={{ marginLeft: 15 }}
                        />
                    </>
                }
                extra={[
                    <Fragment key="current-deadline-text">
                        {showDeadline && (
                            <Typography.Text
                                type={getCurrentWorksheetDeadlineColor(
                                    worksheets[0]?.period || currentPeriod,
                                )}
                            >
                                {getCurrentWorkDeadline(
                                    worksheets[0]?.period || currentPeriod,
                                )}
                            </Typography.Text>
                        )}
                    </Fragment>,
                    <Fragment key="send-work-button">
                        {showSendWork && (
                            <Button
                                onClick={onHandleSendWork}
                                type="primary"
                                label={t(
                                    'works.journalist.layout.buttons.sendWork',
                                )}
                                icon={<SendOutlined />}
                            />
                        )}
                    </Fragment>,
                    <Fragment key="add-worksheet-button">
                        {showAddWork && (
                            <Button
                                onClick={onAddWork}
                                label={t(
                                    'works.journalist.layout.buttons.addWork',
                                )}
                                icon={<PlusOutlined />}
                            />
                        )}
                    </Fragment>,
                ]}
            />
            <WorksFiltersTab
                searchParams={searchParams}
                onAddParams={onAddParams}
                onResetFilters={resetFilters}
            />
            <WorksTable
                ref={tableRef}
                works={works}
                onAddParams={onAddParams}
                onDeleteWorks={onDeleteWorks}
                onEditWork={onEditWork}
                tableParams={tableParams}
                setTableParams={setTableParams}
                searchParams={searchParams}
                resetFilters={resetFilters}
            />
            <Outlet />
        </>
    );
};

export default Works;
