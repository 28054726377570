import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Form, Formik, useFormik } from 'formik';

import { notification } from 'antd';
import EmailInput from 'components/inputs/EmailInput';
import TextInput from 'components/inputs/TextInput';
import Modal from 'components/modal/Modal';
import RadioGroup from 'components/inputs/RadioGroup';

import { NetworkError } from 'network';
import { createAccount } from 'network/services/accounts';

import { roleList } from 'utils/constants/list';
import { Role } from 'utils/interfaces/user';
import style from './CreateAccountForm.module.scss';

const CreateAccountForm = () => {
    const [errors, setErrors] = useState<NetworkError>();
    const navigate = useNavigate();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onCancel = () => {
        navigate(-1);
    };

    const handleSubmit = (
        email: string,
        first_name: string,
        last_name: string,
        role: Role,
    ) => {
        createAccount(
            email,
            first_name,
            last_name,
            role,
            () => {
                notification.success({
                    message: t(
                        'accounts.createAccountForm.notification.createAccount.message',
                    ),
                    description: t(
                        'accounts.createAccountForm.notification.createAccount.description',
                    ),
                });
                onCancel();
            },
            onError,
        );
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            role: roleList[0].value as Role,
        },
        validateOnMount: true,
        onSubmit: (values) =>
            handleSubmit(
                values.email,
                values.first_name,
                values.last_name,
                values.role,
            ),
    });

    const onOk = () => {
        formik.handleSubmit();
    };

    const handleChangeRadio = (key: string, e: any) => {
        formik.setFieldValue(key, e);
    };

    return (
        <>
            <Formik initialValues={formik.initialValues} onSubmit={onOk}>
                <Form>
                    <Modal
                        title={t('accounts.createAccountForm.title')}
                        open
                        onCancel={onCancel}
                        onOk={onOk}
                        cancelText={t('accounts.createAccountForm.cancelText')}
                        okText={t('accounts.createAccountForm.okText')}
                    >
                        <EmailInput
                            id="email"
                            label={t('accounts.createAccountForm.email.label')}
                            placeholder={
                                t(
                                    'accounts.createAccountForm.email.placeholder',
                                ) || ''
                            }
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            autoComplete="email"
                            errors={errors}
                            containerStyle={style.newaccountform__input}
                        />
                        <TextInput
                            id="first_name"
                            label={t(
                                'accounts.createAccountForm.first_name.label',
                            )}
                            placeholder={
                                t(
                                    'accounts.createAccountForm.first_name.placeholder',
                                ) || ''
                            }
                            onChange={formik.handleChange}
                            value={formik.values.first_name}
                            errors={errors}
                            containerStyle={style.newaccountform__input}
                        />
                        <TextInput
                            id="last_name"
                            label={t(
                                'accounts.createAccountForm.last_name.label',
                            )}
                            placeholder={
                                t(
                                    'accounts.createAccountForm.last_name.placeholder',
                                ) || ''
                            }
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                            errors={errors}
                            containerStyle={style.newaccountform__input}
                        />
                        <RadioGroup
                            id="role"
                            label={t('accounts.createAccountForm.role.label')}
                            errors={errors}
                            onChangeValue={(e) => handleChangeRadio('role', e)}
                            value={formik.values.role}
                            containerStyle={style.newaccountform__input}
                            options={roleList.slice(0, 2)}
                        />
                    </Modal>
                </Form>
            </Formik>
        </>
    );
};

export default CreateAccountForm;
