import axios, { AxiosResponse, Method, RawAxiosRequestHeaders } from 'axios';
import { notification } from 'antd';
import qs from 'qs';
import i18n from 'locales/i18n';
import { PublicRoutes } from 'router/routes';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || '';

/* Qualsiasi risposta del network */
export interface NetworkResponse {
  status: number;
  data?: any;
  errors?: NetworkError;
  totalItems?: number;
}

export type NetworkError = { [x: string]: string };

type DjangoError = { [x: string]: string | string[] };

const callAPI = async (method: Method, endpoint: string, data?: unknown, params?: any, silent = false): Promise<NetworkResponse> => {
  let client = axios.create({
    paramsSerializer: {
      // Added to have array params as foo=bar&foo=baz instead of foo[]=bar&foo[]=baz
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' })  
    }
  })

  // Remove empty params, not handled by Django
  if (params) {
    Object.keys(params).forEach(key => {
      if (params[key] === '') {
        delete params[key];
      }
    })
  }

  const request = client({method, url: buildEndpoint(endpoint), headers: buildHeaders(), data: JSON.stringify(data), params})

  try {
    const response = await request;
    return buildSuccessResponse(response);
  } catch (error) {
    console.log(error);
    if (axios.isAxiosError(error) && error.response) {
      // Error 401 - token non valida
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = PublicRoutes.BASE;
        return buildErrorResponse(error.response, {'error': 'Oops... (error 401)'}, silent);
      }

      // Error 4xx
      if (error.response.status >= 400 && error.response.status < 500) {
        const errors = retrieveErrors(error.response.data as DjangoError);
        return buildErrorResponse(error.response, errors, silent);
      }

      // Error 500
      return buildErrorResponse(error.response, {'error': 'Oops... (error 500)'}, silent);
    } else {
      // Javascript error
      return buildErrorResponse(undefined, {'error': 'Oops... (error -1)'}, silent);
    }
  }
};

const buildEndpoint = (endpoint: string): string => {
  return BACKEND_URL.replace(/\/+$/, '') + '/' + endpoint.replace(/^\/+/, '').replace(/\/+$/, '') + '/';
};

// risposte da restituire a chi ci invoca con le api
const buildSuccessResponse = (response: AxiosResponse): NetworkResponse => {
  const result: NetworkResponse = {
    status: response.status,
    data: response.data,
    totalItems: parseInt(response.headers?.['pagination-count'] || ''),
  };

  return result;
};

const buildErrorResponse = (response?: AxiosResponse, errors?: NetworkError, silent = false): NetworkResponse => {
  if (errors && errors['error'] && !silent) {
    notification.error({message: errors['error']})
  }

  const result: NetworkResponse = {
    status: response?.status || -1,
    errors,
  };

  return result;
};

// aggiungere negli header sempre
export const buildHeaders = (): RawAxiosRequestHeaders => {
  const token = localStorage.getItem('auth-token');
  const lang = i18n.language;

  if (token) {
    return {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      'Accept-Language': lang,
    };
  } else {
    return {
      'Content-Type': 'application/json',
      'Accept-Language': lang,
    };
  }
};

// ritorna un dizionario
const retrieveErrors = (data: DjangoError): NetworkError => {
  // errori generici non associati ad un field
  if ('non_field_errors' in data) {
    const errors = data['non_field_errors'] as string[];
    return { 'error': errors.join(' ') };
  }
  if ('detail' in data) {
    const error = data['detail'] as string;
    return { 'error': error };
  }
  if ('error' in data) {
    const error = data['error'] as string;
    return { 'error': error };
  }

  // dizionario dinamico: key - value
  let errors: NetworkError = {};
  for (const [key, values] of Object.entries(data)) {
    errors[key] = (values as string[]).join(' ')
  }

  return errors;
};

export default callAPI;
