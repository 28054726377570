import { CaretDownOutlined } from  '@ant-design/icons/lib/icons';
import {
    Button,
    Checkbox,
    Dropdown,
    Input,
    Space,
    Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SelectProps } from 'antd/lib/select';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import style from './FilterSelect.module.scss';

export interface FilterOption {
    value: string | number;
    label: string;
    selected?: boolean;
}

export interface FilterSelectProps extends SelectProps {
    allowMultipleSelect?: boolean;
    options: FilterOption[];
    selectedOptions?: FilterOption[];
    selectPlaceholder: string;
    onApplyFilters: (value: string[]) => void;
    onResetFilters: () => void;
}

const FilterSelect = (props: FilterSelectProps) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<FilterOption[]>([]);
    const [searchValue, setSearchValue] = useState('');

    const handleOpenChange = (flag: boolean) => {
        setOpen(flag);
    };

    const filteredItems = useMemo(
        () =>
            items?.filter((el) =>
                el.label // label is automatically translated
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()),
            ),
        [searchValue, items],
    );

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onResetFilters = () => {
        setItems((prev) => prev.map((el) => ({ ...el, selected: false })));
        props.onResetFilters();
    };

    const onApplyFilters = () => {
        const selectedItems = items?.filter((el) => el.selected);
        props.onApplyFilters(selectedItems.map((el) => el.value.toString()));
        handleOpenChange(false);
    };

    const handleCheckbox = (e: CheckboxChangeEvent, el: FilterOption) => {
        if (props.allowMultipleSelect) {
            setItems((prevItems) =>
                prevItems.map((item) => {
                    if (item.value === el.value) {
                        return {
                            ...item,
                            selected: e.target.checked,
                        };
                    }
                    return item;
                }),
            );
        } else {
            setItems((prevItems) =>
                prevItems.map((item) => {
                    if (item.value === el.value) {
                        return {
                            ...item,
                            selected: e.target.checked,
                        };
                    }
                    return {
                        ...item,
                        selected: false,
                    };
                }),
            );
        }
    };

    useEffect(() => {
        const nextItems = props.options?.map((opt) => ({
            ...opt,
            selected:
                props.selectedOptions && props.selectedOptions.length > 0
                    ? !!props.selectedOptions.find((o) => opt.value === o.value)
                    : false,
        }));

        setItems(nextItems);
    }, [props.options, props.selectedOptions]);

    return (
        <>
            <Dropdown
                placement="bottomLeft"
                arrow
                onOpenChange={handleOpenChange}
                open={open}
                trigger={['click']}
                dropdownRender={() => (
                    <div className={style.dropdown__container}>
                        <Input
                            autoFocus
                            className={style.input}
                            placeholder={props.selectPlaceholder}
                            onChange={onSearch}
                            value={searchValue}
                            allowClear
                        />

                        <Space direction="vertical" style={{ marginBottom: 5 }}>
                            {filteredItems?.length > 0 ? (
                                filteredItems.map((el) => (
                                    <Checkbox
                                        key={el.value}
                                        className={style.checkbox}
                                        checked={el.selected}
                                        onChange={(e) => handleCheckbox(e, el)}
                                    >
                                        {el.label}
                                    </Checkbox>
                                ))
                            ) : (
                                <Typography.Text>
                                    {t('filterSelect.noResults')}
                                </Typography.Text>
                            )}
                        </Space>
                        <Space className={style.buttons}>
                            <Typography.Text
                                className={style.clear}
                                onClick={onResetFilters}
                            >
                                {t('filterSelect.clear')}
                            </Typography.Text>
                            <Button type="primary" onClick={onApplyFilters}>
                                {t('filterSelect.apply')}
                            </Button>
                        </Space>
                    </div>
                )}
            >
                <div
                    onClick={() => setOpen(true)}
                    className={`${style.filter__closed} ${
                        props.selectedOptions &&
                        props.selectedOptions.length > 0 &&
                        style.filled
                    }`}
                >
                    {props.selectedOptions &&
                        props.selectedOptions.length > 0 && (
                            <Typography.Text
                                className={style.filtersCount}
                            >{`(${props.selectedOptions.length})`}</Typography.Text>
                        )}
                    <Typography.Text className={style.placeholder}>
                        {props.placeholder}
                    </Typography.Text>
                    <CaretDownOutlined className={style.icon} />
                </div>
            </Dropdown>
        </>
    );
};

export default FilterSelect;
