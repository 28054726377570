import { Result, ResultProps } from 'antd';
import style from './ResultPage.module.scss';

const ResultPage = (props: ResultProps) => (
    <Result
        {...props}
        className={`${style.resultpage__container} ${props.className}`}
    />
);

export default ResultPage;
