import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, Typography } from 'antd';

import Button from 'components/buttons/Button';
import PasswordInput from 'components/inputs/PasswordInput';
import { logo } from 'assets/images';

import { constants } from 'utils/constants/constants';
import style from './ResetPassword.module.scss';
import { changePasswordWithTokenService } from 'network/services/password';
import { User } from 'utils/interfaces/user';
import { useState } from 'react';
import { PublicRoutes } from 'router/routes';
import { NetworkError } from 'network';
import { t } from 'i18next';

const SignupSchema = Yup.object().shape({
    new_password1: Yup.string()
        .min(constants.login.passwordMinLength, 'Too Short!')
        .matches(/[A-Z]/)
        .required('Required'),
    new_password2: Yup.string()
        .oneOf([Yup.ref('new_password1'), null], 'Passwords must match')
        .required('Required'),
});

const CreatePassword = () => {
    const navigate = useNavigate();
    const { uid, token } = useParams();

    const [errors, setErrors] = useState<NetworkError>();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onSuccess = (user: User) => {
        navigate(PublicRoutes.RESET_PASSWORD_LINK_SUCCESS);
    };

    const handleSubmit = (new_password1: string, new_password2: string) => {
        changePasswordWithTokenService(
            new_password1,
            new_password2,
            uid as string,
            token as string,
            onSuccess,
            onError,
        );
    };

    const formik = useFormik({
        initialValues: {
            new_password1: '',
            new_password2: '',
        },
        validateOnMount: true,
        validationSchema: SignupSchema,
        onSubmit: (values) =>
            handleSubmit(values.new_password1, values.new_password2),
    });

    return (
        <div className={style.resetPassword__container}>
            <div>
                <img alt={constants.appName} src={logo} style={{ height: 35 }} />
            </div>
            <div>
                <Typography.Title
                    level={2}
                    className={style.resetPassword__title}
                >
                    {t('resetPassword.title')}
                </Typography.Title>
                <Typography className={style.resetPassword__subtitle}>
                    {t('resetPassword.subtitle')}
                </Typography>
            </div>
            <form
                className={style.resetPassword__form}
                onSubmit={formik.handleSubmit}
            >
                <PasswordInput
                    id="new_password1"
                    label={t('resetPassword.form.new_password1.label')}
                    placeholder={
                        t('resetPassword.form.new_password1.placeholder') || ''
                    }
                    containerStyle={style.resetPassword__input}
                    onChange={formik.handleChange}
                    value={formik.values.new_password1}
                    errors={errors}
                />
                <PasswordInput
                    id="new_password2"
                    label={t('resetPassword.form.new_password2.label')}
                    placeholder={
                        t('resetPassword.form.new_password2.placeholder') || ''
                    }
                    containerStyle={style.resetPassword__input}
                    onChange={formik.handleChange}
                    value={formik.values.new_password2}
                    errors={errors}
                />
                <Button
                    className={style.resetPassword__btn}
                    type="primary"
                    htmlType="submit"
                    disabled={!formik.isValid}
                    label={t('resetPassword.buttons.confirm')}
                />
            </form>

            {errors && errors['error'] && (
                <Alert
                    message={errors['error']}
                    type="error"
                    showIcon
                    closable
                />
            )}
        </div>
    );
};

export default CreatePassword;
