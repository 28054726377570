interface TableWrapperProps {
    id: string;
    children: React.ReactNode;
}

export const TableWrapper = (props: TableWrapperProps) => (
    <div
        id={props.id}
        style={{
            marginLeft: 25,
            marginRight: 25,
        }}
    >
        {props.children}
    </div>
);
