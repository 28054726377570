import { Navigate, Outlet } from 'react-router-dom';
import { PrivateRoutes } from 'router/routes';
import { useAppSelector } from 'store/hook';

const PublicMiddleware = () => {
    const user = useAppSelector((state) => state.user.currentUser);
    console.log(user);

    if (user) {
        return <Navigate to={PrivateRoutes.HOMEPAGE} />;
    }
    return <Outlet />;
};

export default PublicMiddleware;
