import { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';

import FilterSelect from 'components/select/FilterSelect';
import TableFiltersTab from 'components/bars/TableFilterBar';

import { getWorkStatusList } from 'utils/works';
import { getArticles, getNewspapers } from 'network/services/properties';
import { getAccounts } from 'network/services/accounts';
import { parseTabFilters } from 'utils/table';
import { FilterOptionDict, ParamsDict } from 'utils/interfaces/types';
import { useAppSelector } from 'store/hook';
import { Role } from 'utils/interfaces/user';

interface WorksFiltersTabProps {
    searchParams: URLSearchParams;
    onAddParams: (params: ParamsDict) => void;
    onResetFilters: () => void;
}

const WorksFiltersTab = (props: WorksFiltersTabProps) => {
    const user = useAppSelector((state) => state.user.currentUser);

    const [options, setOptions] = useState<FilterOptionDict>({
        status: getWorkStatusList(user?.role),
    });

    useEffect(() => {
        getAccounts({ page_size: 1000, role: Role.Client }, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                client: items.map((el) => ({
                    value: el.id,
                    label: el.full_name,
                })),
            }));
        });

        getArticles(undefined, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                article: items.map((el) => ({
                    value: el.id,
                    label: el.value,
                })),
            }));
        });

        getNewspapers(undefined, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                newspaper: items.map((el) => ({
                    value: el.id,
                    label: el.value,
                })),
            }));
        });
    }, [props.searchParams]);

    const onSelectFilter = (key: string, value: string[]) => {
        props.onAddParams({ [key]: value.join(), page: 1 });
    };

    const filters: FilterOptionDict = useMemo(() => {
        const filtersKey = ['client', 'status', 'article', 'newspaper'];
        let nextFilters: FilterOptionDict = {};

        filtersKey.forEach((el) => {
            if (props.searchParams.get(el)) {
                console.log(props.searchParams.get(el));
                const parsedFilters = parseTabFilters(
                    props.searchParams.get(el),
                    options,
                    el,
                );
                nextFilters[el] = parsedFilters;
            }
        });
        return nextFilters;
    }, [options, props.searchParams]);


    return (
        <TableFiltersTab
            searchParams={props.searchParams}
            onResetFilters={props.onResetFilters}
        >
            <FilterSelect
                key="client"
                placeholder={t('works.table.filters.client.name')}
                options={options['client']}
                selectPlaceholder={t(
                    'works.table.filters.client.placeholder',
                )}
                selectedOptions={
                    filters['client'] ? filters['client'] : []
                }
                onResetFilters={() => onSelectFilter('client', [])}
                onApplyFilters={(value: string[]) =>
                    onSelectFilter('client', value)
                }
            />
            <FilterSelect
                key="status"
                allowMultipleSelect
                placeholder={t('works.table.filters.status.name')}
                selectPlaceholder={t(
                    'works.table.filters.status.placeholder',
                )}
                selectedOptions={filters['status'] ? filters['status'] : []}
                options={options['status']}
                onResetFilters={() => onSelectFilter('status', [])}
                onApplyFilters={(value: string[]) =>
                    onSelectFilter('status', value)
                }
            />
            <FilterSelect
                key="article"
                allowMultipleSelect
                placeholder={t('works.table.filters.article.name')}
                selectPlaceholder={t(
                    'works.table.filters.article.placeholder',
                )}
                selectedOptions={filters['article'] ? filters['article'] : []}
                options={options['article']}
                onResetFilters={() => onSelectFilter('article', [])}
                onApplyFilters={(value: string[]) =>
                    onSelectFilter('article', value)
                }
            />
            <FilterSelect
                key="newspaper"
                allowMultipleSelect
                placeholder={t('works.table.filters.newspaper.name')}
                selectPlaceholder={t(
                    'works.table.filters.newspaper.placeholder',
                )}
                selectedOptions={
                    filters['newspaper'] ? filters['newspaper'] : []
                }
                options={options['newspaper']}
                onResetFilters={() => onSelectFilter('newspaper', [])}
                onApplyFilters={(value: string[]) =>
                    onSelectFilter('newspaper', value)
                }
            />
        </TableFiltersTab>
    );
};

export default WorksFiltersTab;
