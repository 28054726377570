import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, Typography } from 'antd';

import Button from 'components/buttons/Button';
import PasswordInput from 'components/inputs/PasswordInput';
import { logo } from 'assets/images';

import { changePasswordService } from 'network/services/password';
import { CommonPrivateRoutes } from 'router/routes';
import { User } from 'utils/interfaces/user';

import style from './CreatePassword.module.scss';
import { NetworkError } from 'network';
import { t } from 'i18next';
import { userService } from 'network/services/user';
import { useAppDispatch } from 'store/hook';
import { constants } from 'utils/constants/constants';

const SignupSchema = Yup.object().shape({
    new_password1: Yup.string()
        .min(8, 'Too Short!')
        .matches(/[A-Z]/)
        .required('Required'),
    new_password2: Yup.string()
        .oneOf([Yup.ref('new_password1'), null], 'Passwords must match')
        .required('Required'),
});

const CreatePassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [errors, setErrors] = useState<NetworkError>();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onSuccess = (user: User) => {
        userService(
            () => {
                navigate(CommonPrivateRoutes.CREATE_PASSWORD_SUCCESS);
            },
            () => console.log('error'),
            dispatch,
        );
    };

    const handleSubmit = (new_password1: string, new_password2: string) => {
        changePasswordService(new_password1, new_password2, onSuccess, onError);
    };

    const formik = useFormik({
        initialValues: {
            new_password1: '',
            new_password2: '',
        },
        validateOnMount: true,
        validationSchema: SignupSchema,
        onSubmit: (values) =>
            handleSubmit(values.new_password1, values.new_password2),
    });

    return (
        <div className={style.createPassword__container}>
            <div>
                <img alt={constants.appName} src={logo} style={{ height: 35 }} />
            </div>
            <div>
                <Typography.Title
                    level={2}
                    className={style.createPassword__title}
                >
                    {t('createPassword.title')}
                </Typography.Title>
                <Typography className={style.createPassword__subtitle}>
                    {t('createPassword.subtitle')}
                </Typography>
            </div>
            <form
                className={style.createPassword__form}
                onSubmit={formik.handleSubmit}
            >
                <PasswordInput
                    id="new_password1"
                    label={t('createPassword.form.new_password1.label')}
                    placeholder={
                        t('createPassword.form.new_password1.placeholder') || ''
                    }
                    containerStyle={style.createPassword__input}
                    onChange={formik.handleChange}
                    value={formik.values.new_password1}
                    errors={errors}
                />
                <PasswordInput
                    id="new_password2"
                    label={t('createPassword.form.new_password2.label')}
                    placeholder={
                        t('createPassword.form.new_password1.placeholder') || ''
                    }
                    containerStyle={style.createPassword__input}
                    onChange={formik.handleChange}
                    value={formik.values.new_password2}
                    errors={errors}
                />
                <Button
                    className={style.createPassword__btn}
                    type="primary"
                    htmlType="submit"
                    disabled={!formik.isValid}
                    label={t('createPassword.buttons.confirm')}
                />
            </form>
            {errors && errors['error'] && (
                <Alert
                    message={errors['error']}
                    type="error"
                    showIcon
                    closable
                />
            )}
        </div>
    );
};

export default CreatePassword;
