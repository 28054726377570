import { InfoCircleOutlined } from  '@ant-design/icons/lib/icons';
import { Tooltip, Typography } from 'antd';
import { InputProps as AntInputProps } from 'antd';
import { t } from 'i18next';

import { NetworkError } from 'network';
import React, { JSXElementConstructor, ReactElement, useMemo } from 'react';
import style from './InputWrapper.module.scss';

export interface InputProps extends AntInputProps {
    id: string;
    label: string;
    errors?: NetworkError | undefined;
    children?: any;
    containerStyle?: string;
    innerStyle?: string;
    tooltip?: string;
    optional?: boolean;
}

export interface InputWrapperProps extends InputProps {
    children: ReactElement<any, string | JSXElementConstructor<any>>;
}

const InputWrapper = (props: InputWrapperProps) => {
    const error = useMemo(() => {
        if (props.errors && props.errors[props.id])
            return props.errors[props.id];
        return undefined;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.errors]);

    return (
        <div className={`${props.containerStyle}`} id={props.id}>
            <Typography className={style.wrapper__label}>
                {props.label}
                {props.tooltip && (
                    <Tooltip
                        className={style.wrapper__tooltip}
                        title={props.tooltip}
                    >
                        <InfoCircleOutlined
                            className={style.wrapper__tooltipIcon}
                        />
                    </Tooltip>
                )}
                {props.optional && <span className={style.wrapper__optional}>{t('input.optional')}</span>}
            </Typography>

            {React.cloneElement(props.children, {
                status: error ? 'error' : undefined,
            })}

            {error && (
                <Typography.Text type="danger" className={style.wrapper__label}>
                    {error}
                </Typography.Text>
            )}
        </div>
    );
};

export default InputWrapper;

InputWrapper.defaultProps = {
    optional: false,
 };