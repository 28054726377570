import callAPI, { NetworkError } from 'network';
import { constants } from 'utils/constants/constants';
import { Work } from 'utils/interfaces/works';
import { Worksheet } from 'utils/interfaces/worksheets';

export const getWorksheetById = async (
    worksheetId: number,
    onSuccess: (workheet: Worksheet) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/worksheets/' + worksheetId;

    const data = {
        worksheetId,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

export const sendWorksheet = async (
    id: number,
    onSuccess: (worksheet: Worksheet) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/worksheets/' + id + '/send';

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        undefined,
        false,
    );

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const getWorksByWorksheet = async (
    worksheetId: string,
    params: any,
    onSuccess: (data: Work[], totalItems: number | undefined) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/worksheets/' + worksheetId + '/works';

    for (let [key, value] of Object.entries(params)) {
        const nextValue = value;
        if (typeof nextValue === 'string' && nextValue.includes(',')) {
            params[key] = nextValue.split(',');
        }
    }

    const nextParams = {
        page_size: constants.table.pageSize,
        ...params,
    };

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        nextParams,
        false,
    );

    if (response.data) {
        onSuccess(response.data, response.totalItems);
    }
    if (onError) {
        onError(response.errors!);
    }
};
