import {
    Dispatch,
    forwardRef,
    Key,
    SetStateAction,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';

import { DeleteOutlined } from '@ant-design/icons/lib/icons';
import { Badge, Table, TablePaginationConfig } from 'antd';
import TableActionBar from 'components/bars/TableActionBar';
import { t } from 'i18next';
import { TableParams } from 'utils/interfaces/pagination';
import { getLocaleDateString } from 'utils/date';
import Link from 'antd/lib/typography/Link';
import { Work, WorksTableRefs, WorkStatus } from 'utils/interfaces/works';
import { TableWrapper } from 'components/wrapper/TableWrapper';
import { UserAvatar } from 'components/avatar/UserAvatar';

import style from './WorksTable.module.scss';
import { useAppSelector } from 'store/hook';
import { getWorkStatusBadge, getWorkStatusLabel } from 'utils/works';
import { journalistColumnKeys } from 'utils/constants/table';
import { truncateString } from 'utils/string';
import { createColumn } from 'utils/table';

interface WorksTableProps {
    works: Work[];
    onDeleteWorks: (ids: Key[]) => void;
    tableParams: TableParams;
    setTableParams: Dispatch<SetStateAction<TableParams>>;
    searchParams: URLSearchParams;
    onAddParams: (params: {
        [x: string]: string | boolean | number | undefined;
    }) => void;
    resetFilters: () => void;
    onEditWork: (id: number) => void;
}

const WorksTable = forwardRef<WorksTableRefs, WorksTableProps>((props, ref) => {
    const user = useAppSelector((state) => state.user.currentUser);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedWorks, setSelectedWorks] = useState<Work[]>([]);

    useImperativeHandle(ref, () => ({
        resetSelection() {
            setSelectedRowKeys([]);
            setSelectedWorks([]);
        },
    }));

    const columns: any = useMemo(() => {
        return journalistColumnKeys.map((el) =>
            createColumn(
                "works.table.columns",
                el,
                props.tableParams.sortField,
                props.tableParams.sortOrder,
            ),
        );
    }, [props.tableParams]);

    const actionsBar = useMemo(() => {
        if (selectedWorks.length === 0) {
            return [];
        }

        return selectedWorks.every(
            (w) =>
                w.status === WorkStatus.Draft ||
                w.status === WorkStatus.Filled
        )
            ? [
                  {
                      key: t('works.table.actionsBar.delete'),
                      icon: DeleteOutlined,
                      label: t('works.table.actionsBar.delete'),
                      action: () => props.onDeleteWorks(selectedRowKeys),
                      danger: true,
                      hidden: false,
                  },
              ] : [];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowKeys]);

    const dataSource = useMemo(() => {
        return props.works.map((w) => ({
            key: w.id,
            is_filled: w.is_filled,
            open: (
                <Link onClick={() => props.onEditWork(w.id)}>
                    {t('works.table.columns.open')}
                </Link>
            ),
            date: getLocaleDateString(w.date),
            client: w.client ? (
                <UserAvatar user={w.client} color="secondary" />
            ) : (
                ''
            ),
            client_name: w.client_name,
            article: w.article?.value,
            description: truncateString(w.description, 50),
            code: w.code,
            newspaper: w.newspaper?.value,
            quantity: w.quantity,
            cost: w.cost ? `€ ${w.cost}` : '-',
            total_cost: w.total_cost ? `€  ${w.total_cost}` : '-',
            created_at: getLocaleDateString(w.created_at),
            edited_at: getLocaleDateString(w.edited_at),
            edited_by: w.edited_by ? (
                <UserAvatar user={w.edited_by} color="secondary" />
            ) : (
                ''
            ),
            status: (
                <Badge
                    color={getWorkStatusBadge(w.status)}
                    text={getWorkStatusLabel(w.status, user?.role)}
                />
            ),
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.works]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const nextSelected = props.works.filter((el) =>
            newSelectedRowKeys.includes(el.id),
        );
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedWorks(nextSelected);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: any,
    ) => {
        const ordering = sorter.order
            ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
            : '';
        props.setTableParams({
            ...sorter,
            pagination,
        });
        props.onAddParams({ page: pagination.current, ordering: ordering });
    };

    return (
        <TableWrapper id="journalist-works-table">
            <TableActionBar
                searchPlaceholder={t('works.table.search')}
                selectedRowsLabel={'works.table.actionsBar.selectedWorks'}
                onSearch={(value) =>
                    props.onAddParams({ search: value, page: 1 })
                }
                selectedRowKeys={selectedRowKeys}
                actionsBar={actionsBar}
                searchValue={props.searchParams.get('search') || ''}
            />
            <Table
                dataSource={dataSource}
                showHeader={dataSource?.length > 0}
                rowSelection={rowSelection}
                columns={columns}
                rowClassName={(row) =>
                    !row.is_filled ? style.hightligth__row : ''
                }
                onChange={handleTableChange}
                showSorterTooltip={false}
                pagination={{
                    ...props.tableParams.pagination,
                    position: ['bottomCenter'],
                }}
                scroll={{ x: 'max-content' }}
            />
        </TableWrapper>
    );
});

export default WorksTable;
