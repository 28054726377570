import { Radio, RadioChangeEvent, RadioGroupProps, Space } from 'antd';
import { FilterOption } from 'components/select/FilterSelect';
import { NetworkError } from 'network';
import InputWrapper from './InputWrapper';

export interface InputProps extends RadioGroupProps {
    options: FilterOption[];
    direction?: 'horizontal' | 'vertical' | undefined;
    tooltip?: string;
    optional?: boolean;
    onChangeValue: (value: string) => void;
    id: string;
    label: string;
    errors?: NetworkError | undefined;
    children?: any;
    containerStyle?: string;
    innerStyle?: string;
}
const RadioGroup = (props: InputProps) => {
    const handleChange = (e: RadioChangeEvent) => {
        props.onChangeValue(e.target.value);
    };
    return (
        <InputWrapper
            id={props.id}
            label={props.label}
            containerStyle={props.containerStyle}
            errors={props.errors}
            tooltip={props.tooltip}
            optional={props.optional}
        >
            <Radio.Group onChange={handleChange} value={props.value}>
                <Space direction={props.direction || 'vertical'}>
                    {props.options.map((el) => (
                        <Radio key={el.value} value={el.value}>
                            {el.label}
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </InputWrapper>
    );
};
export default RadioGroup;
