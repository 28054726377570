import { t } from 'i18next';
import { Space, Typography } from 'antd';

import style from './TableFilterTab.module.scss';
import { useMemo } from 'react';

interface WorksFiltersTabProps {
    searchParams: URLSearchParams;
    onResetFilters: () => void;
    children: React.ReactNode;
}

const TableFiltersTab = (props: WorksFiltersTabProps) => {
    
    const showRemoveAll = useMemo(() => {
        for (const [key, value] of Array.from(props.searchParams.entries())) {
            if (!['page', 'ordering', 'search'].includes(key) && value !== '') return true;
        }

        return false;
    }, [props.searchParams]);

    return (
        <>
            <Space className={style.filters__container}>
                {props.children}
                {showRemoveAll && (
                    <Typography.Text
                        onClick={props.onResetFilters}
                        className={style.filters__remove}
                    >
                        {t('filterSelect.removeAll')}
                    </Typography.Text>
                )}
            </Space>
        </>
    );
};

export default TableFiltersTab;
