import { useMemo, useState } from 'react';
import { Dropdown, MenuProps, Select, Space, Tabs, TabsProps } from 'antd';
import { logo } from 'assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { logout } from 'store/reducers/UserSlice';
import i18n from 'locales/i18n';
import {
    GlobalOutlined,
    QuestionCircleOutlined,
    LogoutOutlined,
    UserOutlined,
} from '@ant-design/icons/lib/icons';
import { setCurrentUser } from 'store/reducers/UserSlice';
import { t } from 'i18next';
import { PrivateRoutes, PublicRoutes } from 'router/routes';
import { UserAvatar } from 'components/avatar/UserAvatar';
import style from './NavBar.module.scss';
import { constants } from 'utils/constants/constants';

interface NavBarProps extends TabsProps {
    homepage: string;
}

const NavBar = (props: NavBarProps) => {
    const user = useAppSelector((state) => state.user.currentUser);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const [language, setLanguage] = useState(
        t(`language.${i18n.language.substring(0, 2)}`),
    );

    const onLogout = async () => {
        await dispatch(setCurrentUser(undefined));
        await dispatch(logout());
        navigate(PublicRoutes.BASE);
    };

    const onChangePage = (activeKey: string) => {
        navigate(activeKey);
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
    };

    const activeKey = useMemo(() => {
        return props.items
            ? props.items.find((el) => location.pathname.includes(el.key))?.key
            : undefined;
    }, [location, props.items]);

    const items: MenuProps['items'] = [
        {
            label: (
                <button onClick={() => onChangePage(PrivateRoutes.PROFILE)}>
                    {t('navbar.dropdown.profile')}
                </button>
            ),
            key: 'profile',
            icon: <UserOutlined />,
        },
        {
            label: (
                <button style={{ color: 'red' }} onClick={onLogout}>
                    {t('navbar.dropdown.logout')}
                </button>
            ),
            key: 'logout',
            icon: <LogoutOutlined style={{ color: 'red' }} />,
        },
    ];

    return (
        <nav className={style.navbar__container}>
            <div>
                <div
                    className={style.logo}
                    onClick={() => onChangePage(props.homepage)}
                >
                    <img
                        alt={constants.appName}
                        src={logo}
                        className={style.img}
                    />
                </div>

                {props.items && props.items.length > 0 && (
                    <Tabs
                        onChange={onChangePage}
                        defaultActiveKey={props.items[0].key}
                        activeKey={activeKey}
                        items={props.items}
                        tabBarStyle={{ margin: '0px 0px 0px 30px' }}
                        className={style.tabs}
                    />
                )}
            </div>
            <div>
                <a
                    className={style.support}
                    href={`mailto:${constants.supportMail}`}
                >
                    {t('navbar.support')}
                </a>

                <a
                    className={style.supportIcon}
                    href={`mailto:${constants.supportMail}`}
                >
                    <QuestionCircleOutlined className={style.questionIcon} />
                    {t('navbar.supportIcon')}
                </a>

                <Space className={style.lng}>
                    <GlobalOutlined />
                    <Select
                        defaultValue="it"
                        className={style.select}
                        bordered={false}
                        value={language}
                        onSelect={changeLanguage}
                        options={[
                            {
                                value: 'it',
                                label: t('language.it'),
                            },
                            {
                                value: 'en',
                                label: t('language.en'),
                            },
                        ]}
                    />
                </Space>

                <Dropdown menu={{ items }} trigger={['click']}>
                    <button onClick={(e) => e.preventDefault()}>
                        <UserAvatar user={user} />
                    </button>
                </Dropdown>
            </div>
        </nav>
    );
};
export default NavBar;
