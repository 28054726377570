import { useMemo } from 'react';
import { t } from 'i18next';

import FilterSelect, { FilterOption } from 'components/select/FilterSelect';
import TableFiltersTab from 'components/bars/TableFilterBar';

import { accountStatusList, roleList } from 'utils/constants/list';

interface AccountsFiltersTabProps {
    searchParams: URLSearchParams;
    onAddParams: (params: {
        [x: string]: string | boolean | number | undefined;
    }) => void;
    onResetFilters: () => void;
}

const AccountsFiltersTab = (props: AccountsFiltersTabProps) => {
    const onSelectFilter = (key: string, value: string[]) => {
        props.onAddParams({ [key]: value.join(), page: 1 });
    };

    const filters = useMemo(() => {
        let role: FilterOption[] = [];
        let status: FilterOption[] = [];
        if (props.searchParams.get('role')) {
            const find = roleList.find(
                (el) => el.value === props.searchParams.get('role'),
            );
            if (find) {
                role.push(find);
            }
        }
        if (props.searchParams.get('status')) {
            const find = accountStatusList.find(
                (el) => el.value === props.searchParams.get('status'),
            );
            if (find) {
                status.push(find);
            }
        }
        return { role, status };
    }, [props.searchParams]);

    return (
        <TableFiltersTab
            searchParams={props.searchParams}
            onResetFilters={props.onResetFilters}
        >
            <FilterSelect
                key="role"
                placeholder={t('accounts.table.filters.role.name')}
                options={roleList}
                selectPlaceholder={t('accounts.table.filters.role.placeholder')}
                selectedOptions={filters.role}
                onResetFilters={() => onSelectFilter('role', [])}
                onApplyFilters={(value: string[]) =>
                    onSelectFilter('role', value)
                }
            />
            <FilterSelect
                key="status"
                placeholder={t('accounts.table.filters.status.name')}
                selectPlaceholder={t(
                    'accounts.table.filters.status.placeholder',
                )}
                selectedOptions={filters.status}
                options={accountStatusList}
                onResetFilters={() => onSelectFilter('status', [])}
                onApplyFilters={(value: string[]) =>
                    onSelectFilter('status', value)
                }
            />
        </TableFiltersTab>
    );
};

export default AccountsFiltersTab;
