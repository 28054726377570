import { DatePicker } from 'antd';
import moment from 'moment';
import type { DatePickerProps } from 'antd';

import { NetworkError } from 'network';
import InputWrapper from './InputWrapper';
import { constants } from 'utils/constants/constants';
import { useEffect, useState } from 'react';

export interface InputProps {
    id: string;
    label: string;
    errors: NetworkError | undefined;
    children?: any;
    containerStyle?: string;
    onChange: (date: string) => void;
    value?: string;
    innerStyle?: string;
    tooltip?: string;
    optional?: boolean;
    disabled?: boolean;
}

const DateInput = (props: InputProps) => {
    const [value, setValue] = useState<moment.Moment | null>();

    const onChange: DatePickerProps['onChange'] = (
        date,
        dateString: string,
    ) => {
        if (date) props.onChange(date.format(constants.date.backendFormat))
        setValue(date);
    };

    useEffect(() => {
        if (!props.value) {
            props.onChange(moment().format(constants.date.backendFormat))
            return;
        }
        setValue(moment(props.value, constants.date.backendFormat));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    return (
        <InputWrapper
            id={props.id}
            label={props.label}
            containerStyle={props.containerStyle}
            errors={props.errors}
            tooltip={props.tooltip}
            optional={props.optional}
        >
            <DatePicker
                onChange={onChange}
                value={value}
                className={props.innerStyle}
                format={constants.date.itFormat}
                disabled={props.disabled}
            />
        </InputWrapper>
    );
};
export default DateInput;
