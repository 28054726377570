import { useMemo } from 'react';
import { t } from 'i18next';

import FilterSelect from 'components/select/FilterSelect';
import TableFiltersTab from 'components/bars/TableFilterBar';

import { getWorksheetStatusList } from 'utils/worksheets';
import { parseTabFilters } from 'utils/table';
import { FilterOptionDict, ParamsDict } from 'utils/interfaces/types';
import { useAppSelector } from 'store/hook';

interface WorksheetsFiltersTabProps {
    searchParams: URLSearchParams;
    onAddParams: (params: ParamsDict) => void;
    onResetFilters: () => void;
}

const WorksheetsFiltersTab = (props: WorksheetsFiltersTabProps) => {
    const user = useAppSelector((state) => state.user.currentUser);

    const options: FilterOptionDict = useMemo(() => {
        return {
            status: getWorksheetStatusList(user?.role).slice(0, 3),
        };
    }, [user]);

    const filters: FilterOptionDict = useMemo(() => {
        const filtersKey = ['status'];
        let nextFilters: FilterOptionDict = {};

        filtersKey.forEach((el) => {
            if (props.searchParams.get(el)) {
                console.log(props.searchParams.get(el));
                const parsedFilters = parseTabFilters(
                    props.searchParams.get(el),
                    options,
                    el,
                );
                nextFilters[el] = parsedFilters;
            }
        });
        return nextFilters;
    }, [options, props.searchParams]);
    
    const onSelectFilter = (key: string, value: string[]) => {
        props.onAddParams({ [key]: value.join(), page: 1 });
    };

    return (
        <TableFiltersTab
            searchParams={props.searchParams}
            onResetFilters={props.onResetFilters}
        >
            <FilterSelect
                key="status"
                allowMultipleSelect
                placeholder={t('works.table.filters.status.name')}
                selectPlaceholder={t('works.table.filters.status.placeholder')}
                selectedOptions={filters['status'] ? filters['status'] : []}
                options={options['status']}
                onResetFilters={() => onSelectFilter('status', [])}
                onApplyFilters={(value: string[]) =>
                    onSelectFilter('status', value)
                }
            />
        </TableFiltersTab>
    );
};

export default WorksheetsFiltersTab;
