import callAPI, { NetworkError } from 'network';
import { Property } from 'utils/interfaces/property';

export const getArticles = async (
    params: any,
    onSuccess: (properties: Property[]) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/articles';

    const nextParams = {
        params,
    };

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        nextParams,
        false,
    );

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const createArticle = async (
    value: string,
    onSuccess: (Property: Property[]) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/articles';
    const data = {
       value
    };
    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

export const deleteArticle = async (
    id: string,
    onSuccess: (Property: Property[]) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'delete';
    const endpoint = '/api/articles/' + id;
    const data = {
        id,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data || response.status === 204) { // data = '' if status = 204
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const getNewspapers = async (
    params: any,
    onSuccess: (properties: Property[]) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/newspapers';

    const nextParams = {
        params,
    };

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        nextParams,
        false,
    );

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const createNewspaper = async (
    value: string,
    onSuccess: (Property: Property[]) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/newspapers';
    const data = {
       value
    };
    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

export const deleteNewspaper = async (
    id: string,
    onSuccess: (Property: Property[]) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'delete';
    const endpoint = '/api/newspapers/' + id;
    const data = {
        id,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data || response.status === 204) { // data = '' if status = 204
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};
