import { Fragment, useEffect, useMemo, useState } from 'react';
import {
    Outlet,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { t } from 'i18next';
import { PageHeader, Select, Typography } from 'antd';

import WorksheetsTable from './WorksheetsTable';
import WorksheetsFiltersTab from './WorksheetsFiltersTab';

import { Worksheet } from 'utils/interfaces/worksheets';
import { Period } from 'utils/interfaces/period';
import { replaceUrlString } from 'utils/string';
import { ParamsDict, StringDict } from 'utils/interfaces/types';
import { TableParams } from 'utils/interfaces/pagination';
import { constants } from 'utils/constants/constants';
import { parseOrderingFromUrl } from 'utils/table';
import { getCurrentWorksheetDeadline } from 'utils/worksheets';

import { getPeriods, getWorksheetsByPeriod } from 'network/services/periods';
import { PrivateRoutes, PublicRoutes } from 'router/routes';

// MANAGER WORKSHEET
const Worksheets = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [worksheets, setWorksheets] = useState<Worksheet[]>([]);

    const { periodId } = useParams();
    const [periods, setPeriods] = useState<Period[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            size: 'small',
            pageSize: constants.table.pageSize,
            hideOnSinglePage: true,
        },
    });

    const onGetWorksheetsByPeriod = (id: string, params: any) => {
        getWorksheetsByPeriod(
            id,
            params,
            (items, totalItems) => {
                setWorksheets(items);
                setTableParams((tableParams) => {
                    return {
                        sortOrder: parseOrderingFromUrl(params.ordering)
                            .sortOrder,
                        sortField: parseOrderingFromUrl(params.ordering)
                            .sortField,
                        pagination: {
                            ...tableParams.pagination,
                            pageSize: constants.table.pageSize,
                            current: parseInt(searchParams.get('page') || '1'),
                            total: totalItems,
                        },
                    };
                });
            },
            (error) => console.log('error', error),
        );
    };

    useEffect(() => {
        const params: StringDict = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
        });
        if (!periodId) {
            navigate(PublicRoutes.BASE);
        }
        onGetWorksheetsByPeriod(periodId as string, params);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodId, searchParams]);

    useEffect(() => {
        getPeriods((data) => setPeriods(data));
    }, []);

    const onAddParams = (params: ParamsDict) => {
        for (const [key, value] of Object.entries(params)) {
            searchParams.set(key, value?.toString() ?? '');
        }
        setSearchParams(searchParams);
    };

    const resetFilters = () => {
        setSearchParams();
    };

    const currentPeriod = useMemo(
        () =>
            periods.length > 0
                ? periods?.find((el) => el.id.toString() === periodId)
                : undefined,
        [periodId, periods],
    );

    const onSelectPeriod = (value: string) => {
        navigate(
            replaceUrlString(PrivateRoutes.WORKSHEETS, { periodId: value }),
            {
                replace: true,
            },
        );
    };

    const onOpenWork = (worksheetId?: number) => {
        navigate(
            replaceUrlString(PrivateRoutes.MANAGER_WORKS, {
                periodId: periodId || '',
                worksheetId: worksheetId ? worksheetId.toString() : 'all',
            }),
        );
    };

    const onExport = (url?: string) => {
        if (!url) return;
        window.open(url, '_blank');
        window.location.reload();
    };

    return (
        <>
            <PageHeader
                title={t('worksheets.layout.title')}
                subTitle={
                    <>
                        {periods.length > 0 && (
                            <Select
                                key="select-period-dropdown"
                                defaultValue={currentPeriod?.name}
                                options={periods?.map((el) => ({
                                    value: el.id,
                                    label: el.name,
                                }))}
                                style={{ width: 170 }}
                                onSelect={(opt: any) => onSelectPeriod(opt)}
                            />
                        )}
                    </>
                }
                extra={[
                    <Fragment key="current-deadline-text">
                        <Typography.Text>
                            {getCurrentWorksheetDeadline(
                                worksheets[0]?.period || currentPeriod,
                            )}
                        </Typography.Text>
                    </Fragment>,
                ]}
            />
            <WorksheetsFiltersTab
                searchParams={searchParams}
                onAddParams={onAddParams}
                onResetFilters={resetFilters}
            />
            <WorksheetsTable
                worksheets={worksheets}
                onAddParams={onAddParams}
                onOpenWork={onOpenWork}
                tableParams={tableParams}
                setTableParams={setTableParams}
                searchParams={searchParams}
                resetFilters={resetFilters}
                onExportWorksheet={onExport}
            />
            <Outlet />
        </>
    );
};

export default Worksheets;
