import { Select, SelectProps } from 'antd';
import { FilterOption } from 'components/select/FilterSelect';
import { NetworkError } from 'network';
import InputWrapper from './InputWrapper';

export interface InputProps extends SelectProps {
    id: string;
    label: string;
    errors?: NetworkError | undefined;
    children?: any;
    containerStyle?: string;
    innerStyle?: string;
    options?: FilterOption[];
    onChange: (value: string) => void;
    tooltip?: string;
    optional?: boolean;
}

const SingleSelect = (props: InputProps) => {
    const handleChange = (value: { value: string; label: string }) => {
        props.onChange(value.value);
    };

    return (
        <InputWrapper
            id={props.id}
            label={props.label}
            containerStyle={props.containerStyle}
            errors={props.errors}
            tooltip={props.tooltip}
            optional={props.optional}
        >
            <Select
                {...props}
                labelInValue
                className={props.innerStyle}
                options={props.options}
                onChange={handleChange}

                // TODO: Per favore, fare un double check
                showSearch={true}
                filterOption={(input, option) =>
                    (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </InputWrapper>
    );
};
export default SingleSelect;
