import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, notification, Typography } from 'antd';

import Button from 'components/buttons/Button';
import PasswordInput from 'components/inputs/PasswordInput';

import { constants } from 'utils/constants/constants';
import style from './EditPassword.module.scss';
import { changePasswordService } from 'network/services/password';
import { useState } from 'react';
import { NetworkError } from 'network';
import { t } from 'i18next';

const SignupSchema = Yup.object().shape({
    new_password1: Yup.string()
        .min(constants.login.passwordMinLength, 'Too Short!')
        .matches(/[A-Z]/)
        .required('Required'),
    new_password2: Yup.string()
        .oneOf([Yup.ref('new_password1'), null], 'Passwords must match')
        .required('Required'),
});

const EditPassword = () => {
    const navigate = useNavigate();

    const [errors, setErrors] = useState<NetworkError>();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onCancel = () => {
        navigate(-1);
    };

    const handleSubmit = (new_password1: string, new_password2: string) => {
        changePasswordService(
            new_password1,
            new_password2,
            (user) => {
                notification.success({
                    message: t(
                        'editPassword.notification.passwordChanged.message',
                    ),
                    description: t(
                        'editPassword.notification.passwordChanged.description',
                    ),
                });
                onCancel();
            },
            onError,
        );
    };

    const formik = useFormik({
        initialValues: {
            new_password1: '',
            new_password2: '',
        },
        validateOnMount: true,
        validationSchema: SignupSchema,
        onSubmit: (values) =>
            handleSubmit(values.new_password1, values.new_password2),
    });

    return (
        <div className={style.editPassword__container}>
            <div>
                <Typography.Title
                    level={3}
                    className={style.editPassword__title}
                >
                    {t('editPassword.title')}
                </Typography.Title>
                <Typography className={style.editPassword__subtitle}>
                    {t('editPassword.subtitle')}
                </Typography>
            </div>
            <form
                className={style.editPassword__form}
                onSubmit={formik.handleSubmit}
            >
                <PasswordInput
                    id="new_password1"
                    label={t('editPassword.form.new_password1.label')}
                    placeholder={
                        t('editPassword.form.new_password1.placeholder') || ''
                    }
                    containerStyle={style.editPassword__input}
                    onChange={formik.handleChange}
                    value={formik.values.new_password1}
                    errors={errors}
                />
                <PasswordInput
                    id="new_password2"
                    label={t('editPassword.form.new_password2.label')}
                    placeholder={
                        t('editPassword.form.new_password2.placeholder') || ''
                    }
                    containerStyle={style.editPassword__input}
                    onChange={formik.handleChange}
                    value={formik.values.new_password2}
                    errors={errors}
                />
                <Button
                    className={style.editPassword__btn}
                    type="primary"
                    htmlType="submit"
                    disabled={!formik.isValid}
                    label={t('editPassword.buttons.confirm')}
                />
            </form>
            <Typography.Link underline onClick={() => onCancel()}>
                {t('editPassword.buttons.back')}
            </Typography.Link>

            {errors && errors['error'] && (
                <Alert
                    message={errors['error']}
                    type="error"
                    showIcon
                    closable
                />
            )}
        </div>
    );
};

export default EditPassword;
