// auth.ts
import { User } from 'utils/interfaces/user';
import callAPI, { NetworkError } from 'network';
import { setCurrentUser } from 'store/reducers/UserSlice';

export const userService = async (
    onSuccess: (user: User) => void,
    onError: (errors: NetworkError) => void,
    dispatch: any,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/users/me/';

    // 4 paramentro undefined - params
    // callAPI(method: Method, endpoint: string, data?: any, params?: any, silent?: boolean)
    const response = await callAPI(method, endpoint, undefined, undefined, false);

    if (response.data) {
        dispatch(setCurrentUser(response.data));
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};
