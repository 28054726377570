import { useNavigate } from 'react-router-dom';

import Button from 'components/buttons/Button';
import ResultPage from 'components/result/ResultPage';
import { PublicRoutes } from 'router/routes';
import { t } from 'i18next';

const ResetPasswordSuccess = () => {
    const navigate = useNavigate();

    const onLogin = () => {
        navigate(PublicRoutes.BASE);
    };

    return (
        <ResultPage
            status="success"
            title={t('resetPassword.success.title')}
            subTitle={t('resetPassword.success.subtitle')}
            extra={[
                <Button
                    key="login"
                    type="primary"
                    onClick={onLogin}
                    label= {t('resetPassword.success.buttons.login')}
                />,
            ]}
        />
    );
};

export default ResetPasswordSuccess;
