import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, Space, Typography } from 'antd';

import Button from 'components/buttons/Button';
import PasswordInput from 'components/inputs/PasswordInput';
import EmailInput from 'components/inputs/EmailInput';

import { constants } from 'utils/constants/constants';
import style from './Login.module.scss';
import { logo } from 'assets/images';
import { loginService } from 'network/services/auth';
import { userService } from 'network/services/user';
import { PrivateRoutes } from 'router/routes';
import { useAppDispatch } from 'store/hook';
import { PublicRoutes } from 'router/routes';
import { NetworkError } from 'network';
import { t } from 'i18next';

const SignupSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string()
        .min(constants.login.passwordMinLength)
        .required('Required'),
});

const Login = () => {
    const [errors, setErrors] = useState<NetworkError>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onSuccess = () => {
        userService(
            () => {
                navigate(PrivateRoutes.HOMEPAGE);
            },
            onError,
            dispatch,
        );
    };

    const handleSubmit = (email: string, password: string) => {
        loginService(email, password, onSuccess, onError);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validateOnMount: true,
        validationSchema: SignupSchema,
        onSubmit: (values) => handleSubmit(values.email, values.password),
    });

    const handleForgottenPassword = () => {
        navigate(PublicRoutes.FORGOTTEN_PASSWORD);
    };

    return (
        <div className={style.loginform__container}>
            <div>
                <img
                    alt={constants.appName}
                    src={logo}
                    style={{ height: 35 }}
                />
            </div>
            <Typography.Title level={2}>{t('login.title')}</Typography.Title>
            <form
                className={style.loginform__form}
                onSubmit={formik.handleSubmit}
            >
                <EmailInput
                    id="email"
                    label={t('login.form.email.label')}
                    placeholder={t('login.form.email.placeholder') || ''}
                    containerStyle={style.loginform__input}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    autoComplete="email"
                    errors={errors}
                />
                <PasswordInput
                    id="password"
                    label={t('login.form.password.label')}
                    placeholder={t('login.form.password.placeholder') || ''}
                    containerStyle={style.loginform__input}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    autoComplete="off"
                    errors={errors}
                />
                <Space
                    direction="horizontal"
                    className={style.loginform__forgottenPassowrd}
                >
                    <Typography.Link
                        underline
                        onClick={handleForgottenPassword}
                    >
                        {t('login.buttons.forgottenPassword')}
                    </Typography.Link>
                </Space>
                <Button
                    className={style.loginform__btn}
                    type="primary"
                    htmlType="submit"
                    disabled={!formik.isValid}
                    label={t('login.buttons.login')}
                />
            </form>
            {errors && errors['error'] && (
                <Alert
                    message={errors['error']}
                    type="error"
                    showIcon
                    closable
                />
            )}
        </div>
    );
};

export default Login;
