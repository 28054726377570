import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import NavBar from 'components/bars/NavBar';
import { Outlet } from 'react-router-dom';
import { PrivateRoutes } from 'router/routes';
import style from './Layout.module.scss';

export const ClientLayout = () => {
    return (
        <Layout className={style.mainLayout}>
            <NavBar homepage={PrivateRoutes.HOMEPAGE} />
            <Content className={style.mainContent}>
                <Outlet />
            </Content>
        </Layout>
    );
};
