import { Property } from './property';
import { User } from './user';
import { Worksheet } from './worksheets';

export enum WorkStatus {
    Filled = 'filled', // riga di colore arancione
    Draft = 'draft', // grigio
    Sent = 'sent', // arancio
    Approved = 'approved', // verde
    Validated = 'validated', // blue
}

export interface Work {
    id: number;
    worksheet?: Worksheet;
    journalist: User;
    client: User;
    date: string;
    description: string;
    quantity?: number;
    cost?: number;
    status: WorkStatus;
    created_at: string;
    edited_at?: string;
    edited_by?: User;
    approved_at?: string;
    approved_by?: User;
    article?: Property;
    newspaper?: Property;
    code?: string;
    client_name?: string;
    total_cost: number;
    is_filled?: boolean;
}

export interface WorksTableRefs {
    resetSelection: () => void
}

