import { PageHeader, Space } from 'antd';
import { t } from 'i18next';
import { Outlet } from 'react-router-dom';
import ArticlesTable from './tables/ArticlesTable';
import NewspapersTable from './tables/NewspapersTable';

const Properties = () => {
    return (
        <>
            <PageHeader title={t('properties.layout.title')} />
            <Space direction="horizontal" align="start">
                <ArticlesTable />
                <NewspapersTable />
            </Space>
            <Outlet />
        </>
    );
};

export default Properties;
