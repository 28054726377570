import { FilterOption } from 'components/select/FilterSelect';
import { t } from 'i18next';
import { FilterOptionDict } from './interfaces/types';

export const parseOrderingFromUrl = (ordering: string) => {
    let sortOrder = '';
    let sortField = '';

    if (ordering) {
        sortField = ordering.charAt(0) === '-' ? ordering.slice(1) : ordering;
        sortOrder = ordering.charAt(0) === '-' ? 'descend' : 'ascend';
    }

    return { sortField, sortOrder };
};

export const parseTabFilters = (
    searchParams: string | null,
    options: FilterOptionDict,
    key: string,
): FilterOption[] => {
    let nextFilters: FilterOption[] = [];

    const splitted = searchParams?.split(',');
    splitted?.forEach((element) => {
        const find = options[key]?.find(
            (el) => el.value.toString() === element,
        );
        if (find) {
            nextFilters.push(find);
        }
    });

    return nextFilters;
};

export const createColumn = (
    title: string,
    key: string,
    sortField?: string,
    sortOrder?: string,
    fixed?: string,
) => {
    if (key === 'open') {
        return {
            title: '',
            dataIndex: key,
            key: key,
            width: 70,
            sorter: false,
            fixed: false,
            sortOrder: null,
        };
    }
    return {
        title: t(`${title}.${key}`),
        dataIndex: key,
        key: key,
        sorter: true,
        fixed: key === fixed ? 'right' : false,
        sortOrder: sortField === key && sortOrder ? sortOrder : null,
    };
};