export interface User {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
    role: Role;
    is_enrolled: boolean;
}

export enum Role {
    Journalist = 'journalist',
    Manager = 'manager',
    Client = 'client',
}

