import { InputNumber, InputNumberProps } from 'antd';
import { NetworkError } from 'network';
import InputWrapper from './InputWrapper';

export interface InputProps extends InputNumberProps {
    id: string;
    label: string;
    errors: NetworkError | undefined;
    children?: any;
    containerStyle?: string;
    innerStyle?: string;
    tooltip?: string;
    optional?: boolean;
}

const NumberInput = (props: InputProps) => {
    return (
        <InputWrapper
            id={props.id}
            label={props.label}
            containerStyle={props.containerStyle}
            errors={props.errors}
            tooltip={props.tooltip}
            optional={props.optional}
        >
            <InputNumber {...props} className={props.innerStyle} />
        </InputWrapper>
    );
};
export default NumberInput;
