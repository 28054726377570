import { User } from './user';

export interface Account extends User {
    is_active: boolean;
    date_joined: Date;
}

export enum AccountStatus {
    Active = 'green',
    Inactive = 'red',
    Waiting = 'orange',
}

