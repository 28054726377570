import { FilterOption } from 'components/select/FilterSelect';
import { t } from 'i18next';
import { AccountStatus } from 'utils/interfaces/account';
import { Role } from 'utils/interfaces/user';

export const roleList: FilterOption[] = [
    { value: Role.Journalist, label: t('role.journalist') },
    { value: Role.Client, label: t('role.client') },
    { value: Role.Manager, label: t('role.manager') },
];

export const accountStatusList: FilterOption[] = [
    {
        value: AccountStatus.Active,
        label: t('accountStatus.active'),
    },
    {
        value: AccountStatus.Inactive,
        label: t('accountStatus.inactive'),
    },
    {
        value: AccountStatus.Waiting,
        label: t('accountStatus.waiting'),
    },
];




