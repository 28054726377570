import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hook';

import ResetPasswordSuccess from 'pages/password/reset/ResetPasswordSuccess';
import ForgottenPasswordSuccess from 'pages/password/forgotten/ForgottenPasswordSuccess';
import Login from 'pages/login/Login';
import Profile from 'pages/profile/Profile';
import Properties from 'pages/properties/Properties';
import EditPassword from 'pages/password/edit/EditPassword';
import CreatePassword from 'pages/password/create/CreatePassword';
import CreatePasswordSuccess from 'pages/password/create/CreatePasswordSuccess';
import Worksheets from 'pages/worksheets/Worksheets';
import Loading from 'pages/loading/Loading';
import ForgottenPassword from 'pages/password/forgotten/ForgottenPassword';
import ResetPassword from 'pages/password/reset/ResetPassword';
import Accounts from 'pages/accounts/Accounts';
import CreateAccountForm from 'pages/accounts/CreateAccountForm';
import CreateArticleForm from 'pages/properties/forms/CreateArticleForm';
import CreateNewspaperForm from 'pages/properties/forms/CreateNewspaperForm';
import Homepage from 'pages/homepage/Homepage';

import ClientWorks from 'pages/works/client/Works';
import ClientCreateWork from 'pages/works/client/CreateWork';
import ClientEditWork from 'pages/works/client/EditWork';

import JournalistWorks from 'pages/works/journalist/Works';
import JournalistCreateWork from 'pages/works/journalist/CreateWork';
import JournalistEditWork from 'pages/works/journalist/EditWork';

import ManagerWorks from 'pages/works/manager/Works';

import { JournalistLayout } from 'router/layouts/JournalistLayout';
import { ClientLayout } from 'router/layouts/ClientLayout';
import { ManagerLayout } from 'router/layouts/ManagerLayout';

import { userService } from 'network/services/user';
import { CommonPrivateRoutes, PrivateRoutes, PublicRoutes } from './routes';

import PrivateMiddleware from './middlewares/PrivateMiddleware';
import PublicMiddleware from './middlewares/PublicMiddleware';

import { Role } from 'utils/interfaces/user';
import EditWork from 'pages/works/manager/EditWork';

const AppRouter = () => {
    const userToken = localStorage.getItem('auth-token');
    const user = useAppSelector((state) => state.user.currentUser);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userToken) console.log('is loading');
        else
            userService(
                () => {
                    // navigate(PrivateRoutes.HOMEPAGE);  // Removed to avoid redirect always on Home (CHECK IF IT WORKS)
                },
                () => {
                    navigate(PublicRoutes.BASE);
                },
                dispatch,
            );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Suspense fallback={<Loading />}>
            {/* PUBLIC Layout */}
            <Routes>
                <Route path={PublicRoutes.BASE} element={<PublicMiddleware />}>
                    <Route index element={<Login />} />
                    <Route
                        path={PublicRoutes.FORGOTTEN_PASSWORD}
                        element={<ForgottenPassword />}
                    />
                    <Route
                        path={PublicRoutes.FORGOTTEN_PASSWORD_SUCCESS}
                        element={<ForgottenPasswordSuccess />}
                    />
                    <Route
                        path={PublicRoutes.RESET_PASSWORD_LINK}
                        element={<ResetPassword />}
                    />
                    <Route
                        path={PublicRoutes.RESET_PASSWORD_LINK_SUCCESS}
                        element={<ResetPasswordSuccess />}
                    />
                </Route>
                {/* PRIVATE Layout */}
                <Route element={<PrivateMiddleware />}>
                    {user?.role === Role.Manager && (
                        <Route element={<ManagerLayout />}>
                            <Route
                                path={PrivateRoutes.ACCOUNTS}
                                element={<Accounts />}
                            >
                                <Route
                                    path={PrivateRoutes.NEW}
                                    element={<CreateAccountForm />}
                                />
                            </Route>
                            <Route
                                path={PrivateRoutes.PROPS}
                                element={<Properties />}
                            >
                                <Route
                                    path={PrivateRoutes.NEW_ARTICLE}
                                    element={<CreateArticleForm />}
                                />
                                <Route
                                    path={PrivateRoutes.NEW_NEWSPAPER}
                                    element={<CreateNewspaperForm />}
                                />
                            </Route>
                            <Route
                                path={PrivateRoutes.HOMEPAGE}
                                element={<Homepage />}
                            />
                            <Route
                                path={PrivateRoutes.WORKSHEETS}
                                element={<Worksheets />}
                            />
                            <Route
                                path={PrivateRoutes.MANAGER_WORKS}
                                element={<ManagerWorks />}
                            >
                                <Route
                                    path={PrivateRoutes.EDIT_WORK}
                                    element={<EditWork />}
                                />
                            </Route>

                            <Route
                                path={PrivateRoutes.PROFILE}
                                element={<Profile />}
                            />
                            <Route
                                path={PrivateRoutes.EDIT_PROFILE}
                                element={<EditPassword />}
                            />
                        </Route>
                    )}

                    {user?.role === Role.Journalist && (
                        <Route element={<JournalistLayout />}>
                            <Route
                                path={PrivateRoutes.HOMEPAGE}
                                element={<Homepage />}
                            />
                            <Route
                                path={PrivateRoutes.WORKS}
                                element={<JournalistWorks />}
                            >
                                <Route
                                    path={PrivateRoutes.NEW}
                                    element={<JournalistCreateWork />}
                                />
                                <Route
                                    path={PrivateRoutes.EDIT_WORK}
                                    element={<JournalistEditWork />}
                                />
                            </Route>
                            <Route
                                path={PrivateRoutes.PROFILE}
                                element={<Profile />}
                            />
                            <Route
                                path={PrivateRoutes.EDIT_PROFILE}
                                element={<EditPassword />}
                            />
                        </Route>
                    )}

                    {user?.role === Role.Client && (
                        <Route element={<ClientLayout />}>
                            <Route
                                path={PrivateRoutes.HOMEPAGE}
                                element={<Homepage />}
                            />
                            <Route
                                path={PrivateRoutes.WORKS}
                                element={<ClientWorks />}
                            >
                                <Route
                                    path={PrivateRoutes.NEW}
                                    element={<ClientCreateWork />}
                                />
                                <Route
                                    path={PrivateRoutes.EDIT_WORK}
                                    element={<ClientEditWork />}
                                />
                            </Route>
                            <Route
                                path={PrivateRoutes.PROFILE}
                                element={<Profile />}
                            />
                            <Route
                                path={PrivateRoutes.EDIT_PROFILE}
                                element={<EditPassword />}
                            />
                        </Route>
                    )}
                </Route>

                <Route
                    path={CommonPrivateRoutes.CREATE_PASSWORD}
                    element={<CreatePassword />}
                />
                <Route
                    path={CommonPrivateRoutes.CREATE_PASSWORD_SUCCESS}
                    element={<CreatePasswordSuccess />}
                />
                <Route
                    path="*"
                    element={<Navigate to={PublicRoutes.BASE} />}
                />
            </Routes>
        </Suspense>
    );
};

export default AppRouter;
