import { t } from 'i18next';
import { getDifferenceBetweenDates } from './date';
import { BadgeStatus } from './interfaces/badge';
import { Period } from './interfaces/period';
import { Role } from './interfaces/user';
import { WorkStatus } from './interfaces/works';

export const getCurrentWorkDeadline = (period: Period | undefined) => {
    const diff = getDifferenceBetweenDates(undefined, period?.end);

    if (diff > 0) {
        return t('works.deadLine.expiredDays', { count: diff });
    }
    if (diff === 0) return t('works.deadLine.today');

    return t('works.deadLine.remainingDays', { count: Math.abs(diff) });
};

export const getWorkStatusList = (role: Role | undefined) => [
    {
        value: WorkStatus.Sent,
        label: t(`workStatus.${role}.sent`),
    },
    {
        value: WorkStatus.Validated,
        label: t(`workStatus.${role}.validated`),
    },
    {
        value: WorkStatus.Approved,
        label: t(`workStatus.${role}.approved`),
    },
];

export const getWorkStatusBadge = (status: WorkStatus | undefined) => {
    if (
        !status ||
        status === WorkStatus.Draft ||
        status === WorkStatus.Filled
    ) {
        return BadgeStatus.Default;
    }
    if (status === WorkStatus.Approved) {
        return BadgeStatus.Success;
    }
    if (status === WorkStatus.Validated) {
        return BadgeStatus.Processing;
    }
    return BadgeStatus.Warning;
};

export const getWorkStatusLabel = (
    status: WorkStatus,
    role: Role | undefined,
) => {
    if (!role) {
        return '';
    }
    if (!status || status === WorkStatus.Draft) {
        return t(`workStatus.${role}.draft`);
    }
    if (status === WorkStatus.Filled) {
        return t(`workStatus.${role}.draft`);
    }
    if (status === WorkStatus.Approved) {
        return t(`workStatus.${role}.approved`);
    }
    if (status === WorkStatus.Validated) {
        return t(`workStatus.${role}.validated`);
    }
    return t(`workStatus.${role}.sent`);
};
