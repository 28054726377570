// auth.ts
import callAPI, { NetworkError } from 'network';

export const loginService = async (
    email: string,
    password: string,
    onSuccess: (token: string) => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/auth/login/';
    const data = {
        email,
        password,
    };

    const response = await callAPI(method, endpoint, data, undefined, false);

    if (response.data) {
        localStorage.setItem('auth-token', response.data.key);
        onSuccess(response.data);
    } else {
        onError(response.errors!);
    }
};

export const logoutService = async (
    onSuccess: () => void,
    onError: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'post';
    const endpoint = '/api/auth/logout/';

    const response = await callAPI(method, endpoint, undefined, undefined, false);

    if (response.data) {
        onSuccess();
    } else {
        onError(response.errors!);
    }
};
