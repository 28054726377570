import { Fragment, Key, useEffect, useMemo, useRef, useState } from 'react';
import {
    Link,
    Outlet,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from 'router/routes';
import { Breadcrumb, notification, PageHeader, Typography } from 'antd';
import { t } from 'i18next';
import { TableParams } from 'utils/interfaces/pagination';
import { constants } from 'utils/constants/constants';
import { parseOrderingFromUrl } from 'utils/table';
import {
    getWorksByPeriod,
    getWorksheetsByPeriod,
} from 'network/services/periods';
import WorksTable from './WorksTable';
import { replaceUrlString } from 'utils/string';
import { ParamsDict, StringDict } from 'utils/interfaces/types';
import WorksFiltersTab from './WorksFiltersTab';
import { getWorksByWorksheet } from 'network/services/worksheets';
import JournalistSelect, {
    CustomOption,
} from 'components/select/JournalistSelect';
import { Work, WorksTableRefs } from 'utils/interfaces/works';
import { WorksheetStatus } from 'utils/interfaces/worksheets';
import { validateWorks } from 'network/services/works';
import { ClockCircleOutlined, ExportOutlined } from '@ant-design/icons';
import { getLocaleDateString } from 'utils/date';
import Button from 'components/buttons/Button';

const { Text } = Typography;

// MANAGER WORK
const ManagerWorks = () => {
    const navigate = useNavigate();

    const { worksheetId, periodId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const tableRef = useRef<WorksTableRefs>(null);

    const [works, setWorks] = useState<Work[]>([]);
    const [options, setOptions] = useState<CustomOption[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            size: 'small',
            pageSize: constants.table.pageSize,
            hideOnSinglePage: true,
        },
    });

    const onEditWork = (id: number) => {
        tableRef.current?.resetSelection();
        navigate(
            replaceUrlString(PrivateRoutes.EDIT_WORK, {
                workId: id.toString(),
            }),
        );
    };

    const onSelectJournalist = (value: string | number) => {
        tableRef.current?.resetSelection();
        navigate(
            replaceUrlString(PrivateRoutes.MANAGER_WORKS, {
                periodId: periodId || '',
                worksheetId: value.toString(),
            }),
            { replace: true },
        );
    };

    const onGetWorksByWorksheet = (id: string, params: any) => {
        getWorksByWorksheet(
            id,
            params,
            (items, totalItems) => {
                setWorks(items);
                setTableParams((tableParams) => {
                    return {
                        sortOrder: parseOrderingFromUrl(params.ordering)
                            .sortOrder,
                        sortField: parseOrderingFromUrl(params.ordering)
                            .sortField,
                        pagination: {
                            ...tableParams.pagination,
                            pageSize: constants.table.pageSize,
                            current: parseInt(searchParams.get('page') || '1'),
                            total: totalItems,
                        },
                    };
                });
            },
            (error) => console.log('error', error),
        );
    };

    const onGetWorksByPeriod = (id: string, params: any) => {
        getWorksByPeriod(
            id,
            params,
            (items, totalItems) => {
                setWorks(items);
                setTableParams((tableParams) => {
                    return {
                        sortOrder: parseOrderingFromUrl(params.ordering)
                            .sortOrder,
                        sortField: parseOrderingFromUrl(params.ordering)
                            .sortField,
                        pagination: {
                            ...tableParams.pagination,
                            pageSize: constants.table.pageSize,
                            current: parseInt(searchParams.get('page') || '1'),
                            total: totalItems,
                        },
                    };
                });
            },
            (error) => console.log('error', error),
        );
    };

    const onAddParams = (params: ParamsDict) => {
        for (const [key, value] of Object.entries(params)) {
            searchParams.set(key, value?.toString() ?? '');
        }
        setSearchParams(searchParams);
    };

    const resetFilters = () => {
        setSearchParams();
    };

    const onValidateWorks = (ids: Key[]) => {
        validateWorks(ids, () => {
            notification.success({
                message: t('works.manager.notification.validateWorks.message', {
                    count: ids.length,
                }),
            });
            tableRef.current?.resetSelection();
            navigate(0);
        });
    };

    useEffect(() => {
        const params: StringDict = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
        });
        if (!periodId) {
            navigate(PublicRoutes.BASE);
        }
        if (worksheetId === 'all') {
            onGetWorksByPeriod(periodId as string, params);
        } else {
            onGetWorksByWorksheet(worksheetId as string, params);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodId, worksheetId, searchParams, navigate]);

    useEffect(() => {
        if (!periodId) {
            navigate(PublicRoutes.BASE);
        }
        getWorksheetsByPeriod(
            periodId as string,
            { page_size: 1000 },
            (items) => {
                const allJournalist: CustomOption[] = [
                    {
                        value: 'all',
                        label: t('works.manager.layout.allJournalist'),
                        selected: false,
                        status: undefined,
                    },
                ];
                setOptions(
                    allJournalist.concat(
                        items.map((el) => ({
                            value: el.id,
                            label: el.journalist.full_name,
                            selected: false,
                            status: el.status,
                        })),
                    ),
                );
            },
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodId]);

    const breadcrumbItems = [
        <Breadcrumb.Item key="worksheets">
            <Link
                to={replaceUrlString(PrivateRoutes.WORKSHEETS, {
                    periodId: periodId ? periodId.toString() : '',
                })}
                style={{ marginLeft: 25, color: 'rgba(0, 0, 0, 0.45)' }}
            >{`<  ${t('works.manager.layout.links.allWorks')}`}</Link>
        </Breadcrumb.Item>,
    ];

    const currentJournalist: CustomOption = useMemo(() => {
        if (options) {
            const find = options?.find(
                (el) => el.value.toString() === worksheetId,
            );
            if (find) {
                return {
                    ...find,
                    selected: true,
                };
            }
        }
        return {
            value: 'all',
            label: t('works.manager.layout.allJournalist'),
            selected: true,
            status: undefined,
        };
    }, [options, worksheetId]);

    console.log(works);
    const showExportWork = useMemo(
        () =>
            (!worksheetId || worksheetId !== 'all') &&
            works.length > 0 &&
            works[0].worksheet?.status === WorksheetStatus.Approved,
        [works, worksheetId],
    );

    return (
        <>
            <Breadcrumb>{breadcrumbItems}</Breadcrumb>
            <PageHeader
                title={t('works.manager.layout.title')}
                subTitle={
                    <JournalistSelect
                        key="journalist"
                        placeholder={t('works.table.filters.client.name')}
                        options={options}
                        selectPlaceholder={t(
                            'works.table.filters.journalist.placeholder',
                        )}
                        selectedOptions={[currentJournalist]}
                        onApplyFilters={(value: string | number) =>
                            onSelectJournalist(value)
                        }
                    />
                }
                extra={[
                    <Fragment key="export-buttons">
                        {showExportWork && works[0].worksheet?.exported_at && (
                            <Text type="secondary" style={{ marginRight: 20 }}>
                                <ClockCircleOutlined
                                    style={{ marginRight: 5 }}
                                />
                                {t('works.manager.layout.buttons.exported_at', {
                                    date: getLocaleDateString(
                                        works[0].worksheet?.exported_at,
                                    ),
                                })}
                            </Text>
                        )}
                        {showExportWork && (
                            <Button
                                label={t('works.manager.layout.buttons.export')}
                                icon={<ExportOutlined />}
                                type="primary"
                                onClick={() => {
                                    const url = works[0]?.worksheet?.export_url;
                                    if (!url) return;
                                    window.open(url, '_blank');
                                    window.location.reload();
                                }}
                            />
                        )}
                    </Fragment>,
                ]}
            />
            <WorksFiltersTab
                searchParams={searchParams}
                onAddParams={onAddParams}
                onResetFilters={resetFilters}
            />
            <WorksTable
                ref={tableRef}
                works={works}
                selectedAll={currentJournalist.value === 'all'}
                onAddParams={onAddParams}
                onEditWork={onEditWork}
                onValidateWorks={onValidateWorks}
                tableParams={tableParams}
                setTableParams={setTableParams}
                searchParams={searchParams}
                resetFilters={resetFilters}
            />
            <Outlet />
        </>
    );
};

export default ManagerWorks;
