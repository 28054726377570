import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import { notification } from 'antd';
import TextInput from 'components/inputs/TextInput';
import Modal from 'components/modal/Modal';

import { NetworkError } from 'network';

import style from './CreatePropertyForm.module.scss';
import { createNewspaper } from 'network/services/properties';

const CreateNewspaperForm = () => {
    const [errors, setErrors] = useState<NetworkError>();
    const [value, setValue] = useState('');
    const navigate = useNavigate();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onCancel = () => {
        navigate(-1);
    };

    const handleSubmit = () => {
        createNewspaper(
            value,
            () => {
                notification.success({
                    message: t(
                        'properties.notification.createProperty.message',
                    ),
                    description: t(
                        'properties.notification.createProperty.description',
                        { name: value },
                    ),
                });
                onCancel();
            },
            onError,
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <>
            <Modal
                title={t('properties.createNewspaperForm.title')}
                open
                onCancel={onCancel}
                onOk={handleSubmit}
                cancelText={t('properties.createNewspaperForm.cancelText')}
                okText={t('properties.createNewspaperForm.okText')}
            >
                <TextInput
                    id="value"
                    label={t('properties.createNewspaperForm.value.label')}
                    placeholder={
                        t('properties.createNewspaperForm.value.placeholder') ||
                        ''
                    }
                    onChange={handleChange}
                    value={value}
                    errors={errors}
                    containerStyle={style.newproperty__input}
                />
            </Modal>
        </>
    );
};

export default CreateNewspaperForm;
