import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/buttons/Button';
import EmailInput from 'components/inputs/EmailInput';

import { constants } from 'utils/constants/constants';
import style from './ForgottenPassword.module.scss';
import { logo } from 'assets/images';
import { Alert, Space, Typography } from 'antd';
import { resetPasswordService } from 'network/services/password';
import { PublicRoutes } from 'router/routes';
import { useState } from 'react';
import { NetworkError } from 'network';
import { t } from 'i18next';

const PasswordSchema = Yup.object().shape({
    email: Yup.string()
        .min(constants.login.emailMinLength, 'Too Short!')
        .required('Required'),
});

const ForgottenPassword = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState<NetworkError>();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onSuccess = (email: string) => {
        navigate(PublicRoutes.FORGOTTEN_PASSWORD_SUCCESS, {
            state: { email: email },
        });
    };

    const onLogin = () => {
        navigate(PublicRoutes.BASE);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: PasswordSchema,
        validateOnMount: true,
        onSubmit: (values) => {
            resetPasswordService(
                values.email,
                () => onSuccess(values.email),
                onError,
            );
        },
    });

    return (
        <div className={style.forgottenPassword__container}>
            <div>
                <img alt={constants.appName} src={logo} style={{ height: 35 }} />
            </div>
            <Typography.Title
                level={2}
                className={style.forgottenPassword__title}
            >
                {t('forgottenPassword.title')}
            </Typography.Title>
            <Typography>{t('forgottenPassword.subtitle')}</Typography>
            <form
                className={style.forgottenPassword__form}
                onSubmit={formik.handleSubmit}
            >
                <EmailInput
                    id="email"
                    label={t('forgottenPassword.form.email.label')}
                    placeholder={
                        t('forgottenPassword.form.email.placeholder') || ''
                    }
                    containerStyle={style.forgottenPassword__input}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    errors={errors}
                />

                <Button
                    className={style.forgottenPassword__btn}
                    type="primary"
                    htmlType="submit"
                    disabled={!formik.isValid}
                    label={t('forgottenPassword.buttons.confirm')}
                />
                <Space
                    direction="horizontal"
                    className={style.forgottenPassword__forgottenPassowrd}
                >
                    <Typography.Link underline onClick={onLogin}>
                        {t('forgottenPassword.buttons.back')}
                    </Typography.Link>
                </Space>
            </form>
            {errors && errors['error'] && (
                <Alert
                    message={errors['error']}
                    type="error"
                    showIcon
                    closable
                />
            )}
        </div>
    );
};

export default ForgottenPassword;
