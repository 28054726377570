import { DeleteOutlined, PlusOutlined } from  '@ant-design/icons/lib/icons';
import { Card, List, Space, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import Button from 'components/buttons/Button';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { Property } from 'utils/interfaces/property';
import style from './PropertyCard.module.scss';

interface PropertyCardProps {
    title: string;
    items: Property[];
    onDelete: (item: Property | undefined) => void;
    onCreate: () => void;
}

const PropertyCard = (props: PropertyCardProps) => {
    const [searchValue, setSearchValue] = useState('');

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const filteredItems = useMemo(
        () =>
            props.items.filter((el) =>
                el.value // label is automatically translated
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()),
            ),
        [searchValue, props.items],
    );

    return (
        <Card className={style.propertycard__container}>
            <Space className={style.propertycard__header}>
                <Typography.Title level={5}>{props.title}</Typography.Title>
                <Typography.Text>
                    {t('properties.table.values', {
                        count: props.items?.length || 0,
                    })}
                </Typography.Text>
            </Space>

            <Search
                placeholder={t('properties.table.search') || ''}
                allowClear
                value={searchValue}
                onChange={onSearch}
                className={style.propertycard__searchInput}
            />
            <Button
                icon={<PlusOutlined />}
                label={t('properties.table.buttons.add')}
                className={style.propertycard__addButton}
                onClick={props.onCreate}
            />

            <List
                dataSource={filteredItems}
                className={style.propertycard__list}
                renderItem={(item) => (
                    <div className={style.propertycard__item}>
                        {item.value}
                        <DeleteOutlined
                            className={style.propertycard__deleteIcon}
                            onClick={() => props.onDelete(item)}
                        />
                    </div>
                )}
            />
        </Card>
    );
};

export default PropertyCard;
