import { Avatar, Space, Typography } from 'antd';
import { AvatarProps } from 'antd/lib/skeleton/Avatar';
import { useMemo } from 'react';
import { getFirstNameInitial } from 'utils/accounts';
import { Account } from 'utils/interfaces/account';
import { User } from 'utils/interfaces/user';
import style from './UserAvatar.module.scss';

export interface UserAvatarProps extends AvatarProps {
    user?: Account | User;
    color?: string; // 'secondary'
    onClick?: () => void;
}

export const UserAvatar = (props: UserAvatarProps) => {
    const initials = useMemo(
        () => getFirstNameInitial(props.user),
        [props.user],
    );

    return (
        <Space className={style.userAvatar__container}>
            <Avatar
                className={`${style.account} ${
                    props.color && style[props.color]
                }`}
                onClick={props.onClick}
            >
                {initials}
            </Avatar>
            <Typography.Text className={style.label}>
                {props.user?.full_name}
            </Typography.Text>
        </Space>
    );
};
