import { t } from 'i18next';
import { getDifferenceBetweenDates } from './date';
import { BadgeStatus } from './interfaces/badge';
import { Period } from './interfaces/period';
import { Role } from './interfaces/user';
import { WorksheetStatus } from './interfaces/worksheets';

export const getCurrentWorksheetDeadline = (period: Period | undefined) => {
    const diff = getDifferenceBetweenDates(undefined, period?.end);

    if (diff > 0) {
        return t('worksheets.deadLine.expiredDays', { count: diff });
    }
    if (diff === 0) return t('worksheets.deadLine.today');

    return t('worksheets.deadLine.remainingDays', { count: Math.abs(diff) });
};

export const getCurrentWorksheetDeadlineColor = (
    period: Period | undefined,
) => {
    const diff = getDifferenceBetweenDates(undefined, period?.end);

    if (diff > 0) {
        return 'danger';
    }
    if (diff === 0) return 'warning';

    return undefined;
};

export const getWorksheetStatusBadge = (status?: WorksheetStatus) => {
    if (!status || status === WorksheetStatus.Draft) {
        return BadgeStatus.Default;
    }
    if (status === WorksheetStatus.Approved) {
        return BadgeStatus.Success;
    }
    if (status === WorksheetStatus.Validated) {
        return BadgeStatus.Processing;
    }
    return BadgeStatus.Warning;
};

export const getWorksheetStatusLabel = (
    status: WorksheetStatus | undefined,
    role: Role | undefined,
) => {
    if (!role) {
        return '';
    }
    if (!status || status === WorksheetStatus.Draft) {
        return t(`worksheetStatus.${role}.draft`);
    }
    if (status === WorksheetStatus.Approved) {
        return t(`worksheetStatus.${role}.approved`);
    }
    if (status === WorksheetStatus.Validated) {
        return t(`worksheetStatus.${role}.validated`);
    }
    return t(`worksheetStatus.${role}.sent`);
};

export const getWorksheetStatusList = (role: Role | undefined) => [
    {
        value: WorksheetStatus.Sent,
        label: t(`worksheetStatus.${role}.sent`),
    },
    {
        value: WorksheetStatus.Validated,
        label: t(`worksheetStatus.${role}.validated`),
    },
    {
        value: WorksheetStatus.Approved,
        label: t(`worksheetStatus.${role}.approved`),
    },
    {
        value: WorksheetStatus.Draft,
        label: t(`worksheetStatus.${role}.draft`),
    },
];
