import { Input as AntInput } from 'antd';
import InputWrapper, { InputProps } from './InputWrapper';

const TextInput = (props: InputProps) => {
    return (
        <InputWrapper
            id={props.id}
            label={props.label}
            containerStyle={props.containerStyle}
            errors={props.errors}
            tooltip={props.tooltip}
            optional={props.optional}
        >
            <AntInput {...props} type="text" className={props.innerStyle} />
        </InputWrapper>
    );
};
export default TextInput;
