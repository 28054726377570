import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { notification } from 'antd';

import { NetworkError } from 'network';
import { useAppSelector } from 'store/hook';
import { FilterOptionDict } from 'utils/interfaces/types';
import { getAccounts } from 'network/services/accounts';
import { getArticles, getNewspapers } from 'network/services/properties';
import { createWork, CreateWorkParams } from 'network/services/works';
import ClientWorkForm from 'components/forms/ClientWorkForm';
import { Role } from 'utils/interfaces/user';

const CreateWork = () => {
    const user = useAppSelector((state) => state.user.currentUser);
    const [options, setOptions] = useState<FilterOptionDict>({});
    const [errors, setErrors] = useState<NetworkError>();
    const navigate = useNavigate();

    const onError = (errors: NetworkError) => {
        setErrors(errors);
    };

    const onCancel = () => {
        navigate(-1);
    };

    const handleSubmit = (values: CreateWorkParams) => {
        createWork(
            { ...values, client: user?.id.toString() },
            () => {
                notification.success({
                    message: t('works.client.notification.createWorkForm.message'),
                });
                onCancel();
            },
            onError,
        );
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: '',
            description: '',
            code: '',
            client_name: '',
            journalist: '',
            article: undefined,
            newspaper: undefined,
            quantity: 1,
            cost: 1,
        } as CreateWorkParams,
        onSubmit: (values) => handleSubmit(values),
    });

    useEffect(() => {
        getAccounts({ page_size: 1000, role: Role.Journalist }, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                journalist: items.map((el) => ({
                    value: el.id,
                    label: el.full_name,
                })),
            }));
        });

        getArticles(undefined, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                article: items.map((el) => ({
                    value: el.id,
                    label: el.value,
                })),
            }));
        });

        getNewspapers(undefined, (items) => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                newspaper: items.map((el) => ({
                    value: el.id,
                    label: el.value,
                })),
            }));
        });
    }, []);

    const handleChangeInput = (e: any) => {
        formik.handleChange(e);
    };

    const handleChangeSelect = (key: string, e: any) => {
        formik.setFieldValue(key, e);
    };

    const onOk = () => {
        formik.handleSubmit();
    };

    return (
        <ClientWorkForm
            title={t('works.createWorkForm.title')}
            cancelText={t('works.createWorkForm.cancelText')}
            okText={t('works.createWorkForm.okText')}
            onOk={onOk}
            onCancel={onCancel}
            initialValues={formik.initialValues}
            values={formik.values}
            onChangeInput={handleChangeInput}
            onChangeSelect={handleChangeSelect}
            errors={errors}
            options={options}
        />
    );
};

export default CreateWork;
