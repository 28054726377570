import { t } from 'i18next';
import { Account, AccountStatus } from './interfaces/account';
import { User } from './interfaces/user';

export const getAccountStatus = (account: Account) => {
    if (!account.is_enrolled) {
        return AccountStatus.Waiting;
    }
    if (account.is_active) {
        return AccountStatus.Active;
    } else {
        return AccountStatus.Inactive;
    }
};

export const getAccountStatusLabel = (account: Account) => {
    if (!account.is_enrolled) {
        return t('accountStatus.waiting');
    }
    if (account.is_active) {
        return t('accountStatus.active');
    } else {
        return t('accountStatus.inactive');
    }
};

export const getFirstNameInitial = (user: User | Account | undefined) =>
    user ? `${user?.first_name?.charAt(0)}` : 'U';
