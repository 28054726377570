import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import NavBar from 'components/bars/NavBar';
import { t } from 'i18next';
import { Outlet } from 'react-router-dom';
import { PrivateRoutes } from 'router/routes';
import style from './Layout.module.scss';


export const ManagerLayout = () => {
    const navLinks: any = [
        {
            key: PrivateRoutes.HOMEPAGE,
            label: t('navbar.works'),
        },
        {
            key: PrivateRoutes.ACCOUNTS,
            label: t('navbar.accounts'),
        },
        {
            key: PrivateRoutes.PROPS,
            label: t('navbar.properties'),
        },
        {
            key: 'profile',  // Used only to handle navigation on Profile
            label: ''
        }
    ];
    return (
        <Layout className={style.mainLayout}>
            <NavBar items={navLinks} homepage={navLinks[0].key} />
            <Content className={style.mainContent}>
                <Outlet />
            </Content>
        </Layout>
    );
};
