export const replaceUrlString = (
    route: string,
    dict: { [x: string]: string },
) => {
    let result = route;

    for (const [key, value] of Object.entries(dict)) {
        result = result.replace(`:${key}`, value);
    }
    return result;
};

export const truncateString = (text: string, maxLength: number) => {
    if (!text || text.length < maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
};
