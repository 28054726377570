import { PlusOutlined } from '@ant-design/icons/lib/icons';
import {
    Outlet,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from 'router/routes';
import { Modal, notification, PageHeader, Select } from 'antd';
import { t } from 'i18next';
import Button from 'components/buttons/Button';
import { TableParams } from 'utils/interfaces/pagination';
import { constants } from 'utils/constants/constants';
import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { parseOrderingFromUrl } from 'utils/table';
import { getPeriods, getWorksByPeriod } from 'network/services/periods';
import WorksTable from './WorksTable';
import { Period } from 'utils/interfaces/period';
import WorksFiltersTab from './WorksFiltersTab';
import { replaceUrlString } from 'utils/string';
import { ParamsDict, StringDict } from 'utils/interfaces/types';
import { approveWorks, deleteWorks } from 'network/services/works';
import { getDifferenceBetweenDates } from 'utils/date';
import { WorksTableRefs } from 'utils/interfaces/works';

// CLIENT WORKS
const Works = () => {
    const navigate = useNavigate();

    const { periodId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const tableRef = useRef<WorksTableRefs>(null);

    const [works, setWorks] = useState<any[]>([]);
    const [periods, setPeriods] = useState<Period[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            size: 'small',
            pageSize: constants.table.pageSize,
            hideOnSinglePage: true,
        },
    });

    const onAddWork = () => {
        tableRef.current?.resetSelection();
        navigate({
            pathname: PrivateRoutes.NEW,
            search: searchParams.toString(),
        });
    };

    const onSelectPeriod = (value: string) => {
        tableRef.current?.resetSelection();
        navigate(replaceUrlString(PrivateRoutes.WORKS, { periodId: value }), {
            replace: true,
        });
    };

    const onEditWork = (id: number) => {
        tableRef.current?.resetSelection();
        navigate(
            replaceUrlString(PrivateRoutes.EDIT_WORK, {
                workId: id.toString(),
            }),
        );
    };

    const onGetWorksByPeriod = (id: string, params: any) => {
        getWorksByPeriod(
            id,
            params,
            (items, totalItems) => {
                setWorks(items);
                setTableParams((tableParams) => {
                    return {
                        sortOrder: parseOrderingFromUrl(params.ordering)
                            .sortOrder,
                        sortField: parseOrderingFromUrl(params.ordering)
                            .sortField,
                        pagination: {
                            ...tableParams.pagination,
                            pageSize: constants.table.pageSize,
                            current: parseInt(searchParams.get('page') || '1'),
                            total: totalItems,
                        },
                    };
                });
            },
            (error) => console.log('error', error),
        );
    };

    const onAddParams = (params: ParamsDict) => {
        for (const [key, value] of Object.entries(params)) {
            searchParams.set(key, value?.toString() ?? '');
        }
        setSearchParams(searchParams);
    };

    const resetFilters = () => {
        setSearchParams();
    };

    const onApproveWorks = (ids: Key[]) => {
        approveWorks(ids, () => {
            notification.success({
                message: t('works.client.notification.approveWorks.message', {
                    count: ids.length,
                }),
               
            });
            tableRef.current?.resetSelection();
            navigate(0);
        });
    };

    const onDeleteWorks = (ids: Key[]) => {
        if (!ids || ids.length === 0) {
            return;
        }
        Modal.confirm({
            title: t('works.journalist.modals.deleteWorks.title'),
            content: t('works.journalist.modals.deleteWorks.content'),
            okText: t('works.journalist.modals.deleteWorks.okText'),
            cancelText: t('works.journalist.modals.deleteWorks.cancelText'),
            onOk: () => handleDeleteWorks(ids),
        });
    };

    const handleDeleteWorks = (ids: Key[]) => {
        deleteWorks(ids, () => {
            notification.success({
                message: t('works.journalist.notification.deleteWorks.message', {
                    count: ids.length,
                }),
            });
            tableRef.current?.resetSelection();
            setWorks((previousWorks) =>
                previousWorks.filter((el) => !ids.includes(el.id)),
            );
        });
    };

    useEffect(() => {
        const params: StringDict = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
        });
        if (!periodId) {
            navigate(PublicRoutes.BASE);
        }
        onGetWorksByPeriod(periodId as string, params);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, periodId, searchParams]);

    useEffect(() => {
        getPeriods((data) => setPeriods(data));
    }, []);

    const currentPeriod = useMemo(
        () =>
            periods.length > 0
                ? periods?.find((el) => el.id.toString() === periodId)
                : undefined,
        [periodId, periods],
    );

    const showAddWork = useMemo(
        () =>
            currentPeriod?.end
                ? getDifferenceBetweenDates(undefined, currentPeriod?.end) <=
                  constants.showAddClientWork
                : false,

        [currentPeriod],
    );
    return (
        <>
            <PageHeader
                title={t('works.client.layout.title')}
                subTitle={
                    <>
                        {periods.length > 0 && (
                            <Select
                                defaultValue={currentPeriod?.name}
                                options={periods?.map((el) => ({
                                    value: el.id,
                                    label: el.name,
                                }))}
                                style={{ width: 170 }}
                                onSelect={(opt: any) => onSelectPeriod(opt)}
                            />
                        )}
                    </>
                }
                extra={[
                    <>
                        {showAddWork && (
                            <Button
                                key="add-work-button"
                                onClick={onAddWork}
                                label={t('works.client.layout.buttons.addWork')}
                                icon={<PlusOutlined />}
                            />
                        )}
                    </>,
                ]}
            />
            <WorksFiltersTab
                searchParams={searchParams}
                onAddParams={onAddParams}
                onResetFilters={resetFilters}
            />
            <WorksTable
                ref={tableRef}
                works={works}
                onAddParams={onAddParams}
                onApproveWorks={onApproveWorks}
                onDeleteWorks={onDeleteWorks}
                onEditWork={onEditWork}
                tableParams={tableParams}
                setTableParams={setTableParams}
                searchParams={searchParams}
                resetFilters={resetFilters}
            />
            <Outlet />
        </>
    );
};

export default Works;
