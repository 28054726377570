import { Modal, notification } from 'antd';
import PropertyCard from 'components/properties/PropertyCard';
import { t } from 'i18next';
import { deleteArticle, getArticles } from 'network/services/properties';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from 'router/routes';
import { Property } from 'utils/interfaces/property';

const ArticlesTable = () => {
    const navigate = useNavigate();

    const [articles, setArticles] = useState<Property[]>([]);

    const onCreateArticle = () => {
        navigate({
            pathname: PrivateRoutes.NEW_ARTICLE,
        });
    };

    const onGetArticles = (params?: any) => {
        getArticles(params, (items) => {
            setArticles(items);
        });
    };

    useEffect(() => {
        onGetArticles();
    }, [navigate]);

    const handleDeleteArticle = (item: Property) => {
        deleteArticle(item.id, () => {
            setArticles(articles.filter((el) => el.id !== item.id));
            notification.success({
                message: t('properties.notification.deleteProperty.message', {
                    name: item.value,
                }),
                description: t('properties.notification.deleteProperty.description', {
                    name: item.value,
                }),
            });
        });
    };

    const onDeleteArticle = (item: Property | undefined) => {
        if (!item) {
            return;
        }
        Modal.confirm({
            title: t('properties.modals.deleteProperty.title', {
                name: item.value,
            }),
            content: t('properties.modals.deleteProperty.content'),
            okText: t('properties.modals.deleteProperty.okText'),
            cancelText: t('properties.modals.deleteProperty.cancelText'),
            onOk: () => handleDeleteArticle(item),
        });
    };
    return (
        <PropertyCard
            title={t('properties.table.columns.article')}
            items={articles}
            onDelete={onDeleteArticle}
            onCreate={onCreateArticle}
        />
    );
};

export default ArticlesTable;
