import callAPI, { NetworkError } from 'network';
import { Period } from 'utils/interfaces/period';
import { constants } from 'utils/constants/constants';
import { Worksheet } from 'utils/interfaces/worksheets';
import { Work } from 'utils/interfaces/works';

export const getActivePeriod = async (
    onSuccess: (data: Period) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/periods/active';

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        undefined,
        false,
    );

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const getPeriods = async (
    onSuccess: (data: Period[]) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/periods/';

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        undefined,
        false,
    );

    if (response.data) {
        onSuccess(response.data);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const getWorksByPeriod = async (
    id: string,
    params: any,
    onSuccess: (data: Work[], totalItems: number | undefined) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/periods/' + id + '/works';

    for (let [key, value] of Object.entries(params)) {
        const nextValue = value;
        if (typeof nextValue === 'string' && nextValue.includes(',')) {
            params[key] = nextValue.split(',');
        }
    }

    const nextParams = {
        page_size: constants.table.pageSize,
        ...params,
    };

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        nextParams,
        false,
    );

    if (response.data) {
        onSuccess(response.data, response.totalItems);
    }
    if (onError) {
        onError(response.errors!);
    }
};

export const getWorksheetsByPeriod = async (
    id: string,
    params: any,
    onSuccess: (data: Worksheet[], totalItems: number | undefined) => void,
    onError?: (errors: NetworkError) => void,
): Promise<void> => {
    const method = 'get';
    const endpoint = '/api/periods/' + id + '/worksheets';

    for (let [key, value] of Object.entries(params)) {
        const nextValue = value;
        if (typeof nextValue === 'string' && nextValue.includes(',')) {
            params[key] = nextValue.split(',');
        }
    }

    const nextParams = {
        page_size: constants.table.pageSize,
        ...params,
    };

    const response = await callAPI(
        method,
        endpoint,
        undefined,
        nextParams,
        false,
    );

    if (response.data) {
        onSuccess(response.data, response.totalItems);
    }
    if (onError) {
        onError(response.errors!);
    }
};
