import {
    Dispatch,
    forwardRef,
    SetStateAction,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';

import {
    DeleteOutlined,
    PoweroffOutlined,
    SendOutlined,
} from '@ant-design/icons/lib/icons';
import { Badge, Table, TablePaginationConfig } from 'antd';
import TableActionBar from 'components/bars/TableActionBar';
import { Account } from 'utils/interfaces/account';
import { Role } from 'utils/interfaces/user';
import { t } from 'i18next';
import { TableParams } from 'utils/interfaces/pagination';
import { getLocaleDateString } from 'utils/date';
import { TableWrapper } from 'components/wrapper/TableWrapper';
import { getAccountStatus, getAccountStatusLabel } from 'utils/accounts';
import { accountscolumnsKey } from 'utils/constants/table';
import { createColumn } from 'utils/table';

interface AccountsTableProps {
    accounts: Account[];
    onResendEmailToAccount: (u: Account | undefined) => void;
    onReactiveAccount: (u: Account | undefined) => void;
    onDeactiveAccount: (u: Account | undefined) => void;
    onDeleteAccount: (u: Account | undefined) => void;
    tableParams: TableParams;
    setTableParams: Dispatch<SetStateAction<TableParams>>;
    searchParams: URLSearchParams;
    onAddParams: (params: {
        [x: string]: string | boolean | number | undefined;
    }) => void;
}

export interface AccountsTableRefs {
    resetSelection: () => void;
}

const AccountsTable = forwardRef<AccountsTableRefs, AccountsTableProps>(
    (props, ref) => {
        const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

        useImperativeHandle(ref, () => ({
            resetSelection() {
                setSelectedRowKeys([]);
            },
        }));

        const columns: any = useMemo(() => {
            return accountscolumnsKey.map((el) =>
                createColumn(
                    'accounts.table.columns',
                    el,
                    props.tableParams.sortField,
                    props.tableParams.sortOrder,
                ),
            );
        }, [props.tableParams]);

        const actionsBar = useMemo(() => {
            if (selectedRowKeys.length === 0) {
                return [];
            }
            const u = props.accounts.find((el) => el.id === selectedRowKeys[0]);
            if (!u || u?.role === Role.Manager) {
                return [];
            }
            return [
                {
                    icon: SendOutlined,
                    label: t('accounts.table.actionsBar.resendEmail'),
                    hidden: u.is_enrolled,
                    action: () => props.onResendEmailToAccount(u),
                    danger: false,
                },
                {
                    icon: PoweroffOutlined,
                    label: t('accounts.table.actionsBar.reactiveUser'),
                    hidden: u.is_active || !u.is_enrolled,
                    action: () => props.onReactiveAccount(u),
                    danger: false,
                },
                {
                    icon: PoweroffOutlined,
                    label: t('accounts.table.actionsBar.deactiveUser'),
                    hidden: !u.is_active || !u.is_enrolled,
                    action: () => props.onDeactiveAccount(u),
                    danger: false,
                },
                {
                    icon: DeleteOutlined,
                    label: t('accounts.table.actionsBar.deleteUser'),
                    hidden: false,
                    action: () => props.onDeleteAccount(u),
                    danger: true,
                },
            ];

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedRowKeys]);

        const dataSource = useMemo(() => {
            return props.accounts.map((u) => ({
                key: u.id,
                full_name: u.full_name,
                email: u.email,
                role: t(`role.${u.role.toString()}`),
                status: (
                    <Badge
                        color={getAccountStatus(u)}
                        text={getAccountStatusLabel(u)}
                    />
                ),
                date_joined: getLocaleDateString(u.date_joined),
            }));
        }, [props.accounts]);

        const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
            const lastElement =
                newSelectedRowKeys[newSelectedRowKeys.length - 1];
            setSelectedRowKeys(lastElement ? [lastElement] : []);
        };

        const rowSelection = {
            selectedRowKeys,
            hideSelectAll: true,
            onChange: onSelectChange,
        };

        const handleTableChange = (
            pagination: TablePaginationConfig,
            filters: any,
            sorter: any,
        ) => {
            const ordering = sorter.order
                ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
                : '';
            props.setTableParams({
                ...sorter,
                pagination,
            });
            props.onAddParams({ page: pagination.current, ordering: ordering });
        };

        return (
            <TableWrapper id="accounts-table">
                <TableActionBar
                    searchPlaceholder={t('accounts.table.search')}
                    selectedRowsLabel={
                        'accounts.table.actionsBar.selectedUsers'
                    }
                    onSearch={(value) =>
                        props.onAddParams({ search: value, page: 1 })
                    }
                    selectedRowKeys={selectedRowKeys}
                    actionsBar={selectedRowKeys.length > 0 ? actionsBar : []}
                    searchValue={props.searchParams.get('search') || ''}
                />
                <Table
                    dataSource={dataSource}
                    showHeader={dataSource?.length > 0}
                    rowSelection={rowSelection}
                    columns={columns}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    pagination={{
                        ...props.tableParams.pagination,
                        position: ['bottomCenter'],
                    }}
                    scroll={{ x: 'max-content' }}
                />
            </TableWrapper>
        );
    },
);

export default AccountsTable;
