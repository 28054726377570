export const journalistColumnKeys = [
    'open',
    'date',
    'client',
    'client_name',
    'article',
    'description',
    'code',
    'newspaper',
    'quantity',
    'cost',
    'total_cost',
    'created_at',
    'edited_at',
    'edited_by',
    'status',
];

export const clientColumnKeys = [
    'open',
    'journalist',
    'date',
    'client_name',
    'article',
    'description',
    'code',
    'newspaper',
    'quantity',
    'cost',
    'total_cost',
    'created_at',
    'edited_at',
    'edited_by',
    'status',
];

export const managerColumnKeys = [
    'open',
    'client',
    'date',
    'client_name',
    'article',
    'description',
    'code',
    'newspaper',
    'quantity',
    'cost',
    'total_cost',
    'created_at',
    'edited_at',
    'edited_by',
    'status',
];

export const managerAllColumnKeys = [
    'open',
    'journalist',
    'client',
    'date',
    'client_name',
    'article',
    'description',
    'code',
    'newspaper',
    'quantity',
    'cost',
    'total_cost',
    'created_at',
    'edited_at',
    'edited_by',
    'status',
];

export const accountscolumnsKey = [
    'full_name',
    'email',
    'role',
    'status',
    'date_joined',
];

export const worksheetsColumnsKey = [
    'open',
    'journalist',
    'works_count',
    'updated_at',
    'status',
    'exported_at'
];