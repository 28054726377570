import { Navigate, Outlet } from 'react-router-dom';
import { CommonPrivateRoutes, PublicRoutes } from 'router/routes';
import { useAppSelector } from 'store/hook';

const PrivateMiddleware = () => {
    const user = useAppSelector((state) => state.user.currentUser);
    
    return !user ? (
        <Navigate to={PublicRoutes.BASE} replace />
    ) : (
        <>
            {user && user.is_enrolled === false ? (
                <Navigate to={CommonPrivateRoutes.CREATE_PASSWORD} replace />
            ) : (
                <Outlet />
            )}
        </>
    )
};

export default PrivateMiddleware;
