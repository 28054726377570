import { Outlet, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import EmailInput from 'components/inputs/EmailInput';
import PasswordInput from 'components/inputs/PasswordInput';
import SingleSelect from 'components/inputs/SingleSelect';
import TextInput from 'components/inputs/TextInput';

import { useAppSelector } from 'store/hook';
import { PrivateRoutes } from 'router/routes';

import style from './Profile.module.scss';
import { Space, Typography } from 'antd';

const Profile = () => {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.user.currentUser);

    const onChangePassword = () => {
        navigate(PrivateRoutes.EDIT_PROFILE);
    };

    return (
        <div className={style.profile__container}>
            <Typography.Title level={3} className={style.profile__title}>
                {t('profile.title')}
            </Typography.Title>
            <div className={style.profile__form}>
                <TextInput
                    id="full_name"
                    label={t('profile.form.full_name')}
                    value={user?.full_name}
                    disabled={true}
                    containerStyle={style.profile__input}
                />
                <SingleSelect
                    id="role"
                    label={t('profile.form.role')}
                    value={t(`role.${user?.role}`)}
                    options={[]}
                    onChange={() => {}}
                    disabled={true}
                    containerStyle={style.profile__input}
                    innerStyle={style.profile__select}
                />
                <EmailInput
                    id="email"
                    label={t('profile.form.email')}
                    value={user?.email}
                    disabled={true}
                    containerStyle={style.profile__input}
                />
                <PasswordInput
                    id="client_name"
                    label={t('profile.form.password')}
                    value={user?.email}
                    disabled={true}
                    containerStyle={style.profile__input}
                />

                <Space
                    direction="horizontal"
                    className={style.profile__changePassword}
                >
                    <Typography.Link onClick={onChangePassword}>
                        {t('profile.buttons.change_password')}
                    </Typography.Link>
                </Space>
            </div>
            <Outlet />
        </div>
    );
};

export default Profile;
