import { Modal, notification } from 'antd';
import PropertyCard from 'components/properties/PropertyCard';
import { t } from 'i18next';
import { deleteNewspaper, getNewspapers } from 'network/services/properties';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from 'router/routes';
import { Property } from 'utils/interfaces/property';

const NewspaperTable = () => {
    const navigate = useNavigate();

    const [newspapers, setNewspapers] = useState<Property[]>([]);

    const onCreateNewspaper = () => {
        navigate({
            pathname: PrivateRoutes.NEW_NEWSPAPER,
        });
    };

    const onGetNewspaper = (params?: any) => {
        getNewspapers(params, (items) => {
            setNewspapers(items);
        });
    };

    useEffect(() => {
        onGetNewspaper();
    }, [navigate]);

    const handleDeleteNewspaper = (item: Property) => {
        deleteNewspaper(item.id, () => {
            setNewspapers(newspapers.filter((el) => el.id !== item.id));
            notification.success({
                message: t('properties.notification.deleteProperty.message', {
                    name: item.value,
                }),
                description: t('properties.notification.deleteProperty.description', {
                    name: item.value,
                }),
            });
        });
    };

    const onDeleteNewspaper = (item: Property | undefined) => {
        if (!item) {
            return;
        }
        Modal.confirm({
            title: t('properties.modals.deleteProperty.title', {
                name: item.value,
            }),
            content: t('properties.modals.deleteProperty.content'),
            okText: t('properties.modals.deleteProperty.okText'),
            cancelText: t('properties.modals.deleteProperty.cancelText'),
            onOk: () => handleDeleteNewspaper(item),
        });
    };
    return (
        <PropertyCard
            title={t('properties.table.columns.newspaper')}
            items={newspapers}
            onDelete={onDeleteNewspaper}
            onCreate={onCreateNewspaper}
        />
    );
};

export default NewspaperTable;
