export enum PublicRoutes {
    BASE = '/',
    FORGOTTEN_PASSWORD = '/password/forgotten',
    FORGOTTEN_PASSWORD_SUCCESS = '/password/forgotten/success',
    RESET_PASSWORD_LINK = '/password/reset/:uid/:token',
    RESET_PASSWORD_LINK_SUCCESS = '/password/reset/confirm',
}

export enum CommonPrivateRoutes {
    CREATE_PASSWORD = '/password/create',
    CREATE_PASSWORD_SUCCESS = '/password/create/success',
}


export enum PrivateRoutes {
    PROFILE = '/profile',
    HOMEPAGE = '/works',
    ACCOUNTS = '/accounts',
    PROPS = '/properties',
    EDIT_PROFILE = '/edit-password',


    WORKS = '/periods/:periodId/works',
    WORKSHEETS = '/periods/:periodId/worksheets',
    MANAGER_WORKS = '/periods/:periodId/worksheets/:worksheetId/works',
    
    /*
    utils.ts:781 Uncaught Error: Absolute route path "/new" nested under path "/accounts" is not valid.
    An absolute child route path must start with the combined path of all its parent routes.
    */
    NEW = 'new',
    NEW_ARTICLE = 'article',
    NEW_NEWSPAPER = 'newspaper',
    EDIT_WORK = ":workId/edit"
}
